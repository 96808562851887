import { I18N } from "./i18n";

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = "Si";
lang.GLOBAL_LABEL_NO = "No";
lang.GLOBAL_LABEL_DO_NOT_KNOW = "No se / no recuerdo";
lang.GLOBAL_LABEL_NEXT = "Próximo";
lang.GLOBAL_LABEL_BACK = "Atras";
lang.GLOBAL_LABEL_NONE = "Ninguno";
lang.GLOBAL_LABEL_SUBMIT = "Enviar";
lang.GLOBAL_FORMVALIDATION_REQUIRED = "Requerido";
lang.GLOBAL_FORMVALIDATION_EMAIL =
  "Porfavor, ingrese una dirección de correo Valida";
lang.GLOBAL_ERROR_FORMSUBMISSION = "There was an error submitting the form.";
lang.MAINPAGE_PAGE_TITLE = "Hola, Bienvenidos a Codi";
lang.MAINPAGE_PAGE_TEXT = "Seleccione entre las siguientes opciones:";
lang.MAINPAGE_PAGE_QUESTIONNAIRE = "Responder Cuestionario";
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = "Lista de médicos";
lang.MAINPAGE_PAGE_TEST_IMAGE = "Subir imagen de prueba";
lang.GENERALINFORMATION_PAGE_TITLE =
  "Información general ~ 10 min para completar el cuestionario";
lang.GENERALINFORMATION_GENDERQUESTION_TEXT =
  "¿Qué sexo le asignaron al nacer?";
lang.GENERALINFORMATION_GENDERQUESTION_MALE = "Masculino";
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = "Femenino";
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = "Otro / Prefiero no decirlo";
lang.GENERALINFORMATION_AGEQUESTION_TEXT = "¿Cuál es tu grupo de edad?";
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = "Prefiero no decirlo";
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = "¿Cómo está tu salud?";
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL =
  "Estoy muy en forma y rara vez me enfermo";
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER =
  "Estoy más en forma y más saludable que el promedio";
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY =
  "Estoy menos en forma pero igual de saludable quel promedio";
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = "No soy muy saludable";
lang.GENERALINFORMATION_COMMONQUESTION_TEXT =
  "¿Cuál de los siguientes tiene / aplica para usted?";
lang.GENERALINFORMATION_COMMONQUESTION_NONE = "Ninguno de los siguientes";

lang.GENERALINFORMATION_COMMONQUESTION_MYOCARDIAL_INFARCTION =
  "Infarto de miocardio";
lang.GENERALINFORMATION_COMMONQUESTION_JAUNDICE_LIVERDISEASE =
  "Ictericia y enfermedad hepática";
lang.GENERALINFORMATION_COMMONQUESTION_TB = "Tuberculosis";
lang.GENERALINFORMATION_COMMONQUESTION_HIGN_BLOOD_PRESSURE = "Hipertensión";
lang.GENERALINFORMATION_COMMONQUESTION_RHEUMATOLOGY = "Reumatológicos";
lang.GENERALINFORMATION_COMMONQUESTION_EPILEPSY_SEIZURES =
  "Epilepsia o convulsiones";
lang.GENERALINFORMATION_COMMONQUESTION_ASTHMA_LUNG =
  "Asma u otras afecciones pulmonares";
lang.GENERALINFORMATION_COMMONQUESTION_DIABETES = "Diabetes";
lang.GENERALINFORMATION_COMMONQUESTION_STROKE_TIA =
  "Accidente cerebrovascular o AIT";
lang.GENERALINFORMATION_COMMONQUESTION_STDS = "ETSs";
lang.GENERALINFORMATION_COMMONQUESTION_ALLERGIES = "Alergias";

lang.GENERALINFORMATION_FLUQUESTION_TEXT =
  "¿Recuerda si tiene todas sus vacunas e inmunizaciones?";

lang.LOCATIONANDTRAVEL_PAGE_TITLE =
  "Ubicación y viaje ~ 8 min para completar el cuestionario";
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = "¿Dónde vives ahora?";
lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT = "Ingrese en qué estado vive.";
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT =
  "Ingrese solo las primeras tres letras o números de su código postal.";
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT =
  "¿Has viajado recientemente a otros países?";
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_YES = "Sí";
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_NO = "No";
// lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Sí, después de que salieron del "cierre de emergencia"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS =
  "Por favor, introduzca exactamente 3 caracteres";
lang.TAKEPHOTO_PAGE_HEADER = "Página de Toma de Foto";
lang.TAKEPHOTO_PAGE_BTN = "Tomar foto";
lang.TRAVELCONTINUED_PAGE_TITLE =
  "Viajes ~ 7 min para completar el cuestionario";
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT =
  "¿Qué países has visitado ultimamente?";
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT =
  "¿En qué fecha entraste a ese país?";
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT =
  "¿En qué fecha te fuiste de ese país?";
lang.EXPOSURERISK_PAGE_TITLE =
  "Historia Médica ~ 7 min para completar el cuestionario";
lang.EXPOSURERISK_LINE_TEXT = "¿Cuál es tu línea de trabajo?";
lang.EXPOSURERISK_PRACTICESLIFE_DISEASES =
  "Mencione algunas de las prácticas en su vida diaria que realiza para evitar contraer enfermedades contagiosas o desarrollar enfermedades crónicas.";
lang.EXPOSURERISK_ANYONE_POSSIBILESICK =
  "¿Crees que alguien en casa posiblemente esté enfermo?";
lang.EXPOSURERISK_ANYONE_POSSIBILESICK_YES = "Si";
lang.EXPOSURERISK_ANYONE_POSSIBILESICK_NO = "No";
lang.EXPOSURERISK_ANYONE_ELABORATE =
  "Explique los síntomas y las fechas de sus compañeros de casa, o descríbalos si no corresponde.";

lang.EXPOSURERISK_INDUSTRYWORK_TEXT = "¿En qué industria trabaja?";
lang.EXPOSURERISK_INDUSTRYWORK_AGRICULTURE = "Agricultura";
lang.EXPOSURERISK_INDUSTRYWORK_UTILITIES = "Servicios Públicos";
lang.EXPOSURERISK_INDUSTRYWORK_FINANCE = "Finanzas";
lang.EXPOSURERISK_INDUSTRYWORK_ENTERTAINMENT = "Entretenimiento";
lang.EXPOSURERISK_INDUSTRYWORK_EDUCATION = "Educación";
lang.EXPOSURERISK_INDUSTRYWORK_HEALTHCARE = "Salud";
lang.EXPOSURERISK_INDUSTRYWORK_IT = "Tecnología";
lang.EXPOSURERISK_INDUSTRYWORK_HOSPITALITY = "Hospitalidad";
lang.EXPOSURERISK_INDUSTRYWORK_LEGAL = "Leyes";
lang.EXPOSURERISK_INDUSTRYWORK_MARKETING = "Negocios";
lang.EXPOSURERISK_INDUSTRYWORK_MILITARY = "Militar";
lang.EXPOSURERISK_INDUSTRYWORK_PREFERNOTSAY = "Prefiero no decirlo";

lang.EXPOSURERISK_DIAGNOSED_TEXT =
  "¿Alguien en su familia ha sido diagnosticado con alguna de las siguientes condiciones?";
lang.EXPOSURERISK_DIAGNOSED_HEART = "Cardiopatía";
lang.EXPOSURERISK_DIAGNOSED_DIABETES = "Diabetes";
lang.EXPOSURERISK_DIAGNOSED_HIGHBLOOD = "Hipertensión";
lang.EXPOSURERISK_DIAGNOSED_HIGHCHOLESTEROL = "Colesterol alto";
lang.EXPOSURERISK_DIAGNOSED_CANCER = "Cáncer";
lang.EXPOSURERISK_DIAGNOSED_STROKE = "Ataque";
lang.EXPOSURERISK_DIAGNOSED_MENTALILLNESS = "Enfermedad mental";
lang.EXPOSURERISK_DIAGNOSED_NEURODEGENERATIVE = "Enfermedad neurodegenerativa";
lang.EXPOSURERISK_DIAGNOSED_NONE = "Ninguno";

lang.EXPOSURERISK_CONSUMEDRUGS_TEXT = "¿Consumes alguna droga?";
lang.EXPOSURERISK_YES_PRECRIBED = "Sí, medicamentos recetados";
lang.EXPOSURERISK_YES_HERBAL = "Sí, remedios herbales o vitaminas";
lang.EXPOSURERISK_YES_COUNTER = "Sí, medicamentos de venta libre";
lang.EXPOSURERISK_YES_RECREATIONAL = "Sí, drogas recreativas";
lang.EXPOSURERISK_CONSUMEDRUGS_NONE = "Ninguno";

lang.EXPOSURERISK_PETHOME_TEXT = "¿Tienes mascotas en casa?";
lang.EXPOSURERISK_PETHOME_ONE = "Uno";
lang.EXPOSURERISK_PETHOME_TWO = "Dos o más";
lang.EXPOSURERISK_PETHOME_NONE = "Ninguno";

lang.EXPOSURERISK_PEOPLE_TEXT = "¿Con cuántas personas vives?";
lang.EXPOSURERISK_PEOPLE_ONE = "Uno";
lang.EXPOSURERISK_PEOPLE_TWO = "Dos o más";
lang.EXPOSURERISK_PEOPLE_NONE = "Ninguno, vivo solo.";

lang.EXPOSURERISK_SMOKE_TEXT = "¿Fuma ?";
lang.EXPOSURERISK_SMOKE_ONE = "Sí";
lang.EXPOSURERISK_SMOKE_TWO = "A veces";
lang.EXPOSURERISK_SMOKE_NONE = "No";

lang.EXPOSURERISK_ALCOHOL_TEXT = "¿Consume alcohol?";
lang.EXPOSURERISK_ALCOHOL_NONE = "No";
lang.EXPOSURERISK_ALCOHOL_ONE = "Sí";
lang.EXPOSURERISK_ALCOHOL_TWO = "A veces";
// lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = '¿Fuiste a trabajar durante el encierro de tu país?';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Sí, pero interactué con pocas personas por día.';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Sí, e interactué con muchas personas por día.';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Sí, e interactué con muchas personas / pacientes por día en un entorno de atención médica.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = '¿Qué hizo para evitar contagiarse de COVID-19 fuera de su hogar?';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Siempre mantuve una distancia de 2 m de la gente.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Siempre usé una máscara / cubierta facial.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Nunca pasé más de 30 minutos en interiores / en vehículos con otras personas.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Frecuentemente me lavo las manos y rara vez me toco la cara.';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Fuera de su casa, ¿pasó tiempo con alguien que se había desarrollado o poco después ...?';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'COVID-19 confirmado (diagnóstico positivo)';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Posibles síntomas de COVID-19';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Ninguno de las anteriores';
// lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = '¿Con cuántas personas vives? (Escriba un número, p.ej. 2)';
// lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = '¿Crees que alguien en tu casa podría haber tenido COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = "Sí, posiblemente";
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY =
  "Sí, definitivamente (resultado positivo de la prueba)";
lang.ILLHOUSEMATES_PAGE_TITLE = "Compañeros de vivienda enfermos";
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT =
  "¿Cuántas personas con las que vive han tenido posibles síntomas de COVID-19? Por favor escriba un número, p.ej. 2";
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT =
  "¿Cuándo se enfermó la primera persona en su casa?";
lang.WEREYOUILL_PAGE_TITLE =
  "¿Te has sentido enfermo? ~ 1 min para completar el cuestionario";

lang.WEREYOUILL_UNWELL_TEXT = "¿Te has sentido mal últimamente?";
lang.WEREYOUILL_PHYSICAL_MANIFESTATION =
  "Sí, he tenido manifestaciones físicas.";
lang.WEREYOUILL_PHYSICAL_SYMPTOMS = "Sí, pero sin síntomas físicos.";
lang.WEREYOUILL_NO = "No";

lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE =
  "No, estoy bien. No me he sentido mal desde enero de 2020";
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL =
  "Estoy empezando a sentirme enfermo";
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL =
  "Ahora me siento bastante enfermo";
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = "Me siento muy enfermo";
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER =
  "Todavía estoy enfermo pero me siento mejor";
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE =
  "Me estaba recuperando, pero ahora me siento enfermo otra vez";
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD =
  "Sí, pero me siento completamente recuperado.";
lang.YOURSYMPTOMS_PAGE_TITLE =
  "Sus síntomas ~ 4 min para completar el cuestionario";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT =
  "¿Cuál de los siguientes síntomas tenía / tiene?";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = "Fiebre";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_HEADACHES = "Dolores de Cabeza";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_BOWELSYMPTOMS = "Síntomas intestinales";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VISUALCHANGES =
  "Perdida o disminución de visión";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SWOLLEINGPROBLEMS =
  "Problemas de deglución";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASHES = "Erupciones en la piel";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTLESS_BREATES =
  "Dificultad para respirar";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHESTPAIN = "Dolor en el pecho";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_WEIGTHGAIN = "Aumento de peso";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ABDOMINALPAIN = "Dolor abdominal";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_HEARINGPROBLEMS =
  "Problemas para escuchar";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_URINARYPROBLEMS = "Síntomas urinarios";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_JOINTPAIN =
  "Dolor en las articulaciones";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TIREDNESS = "Cansancio/Fatiga";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSAPPETITE = "Pérdida de apetito";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_WEIGTHLOSS = "Pérdida de peso";

// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Sin síntomas';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Realmente cansado';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Un dolor de cabeza';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Dolor en las articulaciones y / o músculos';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Dolor de garganta';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Nariz congestionada';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Mocos en la Nariz';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Tos seca';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Tos con esputo';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Estornudos';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Fiebre';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Escalofrios';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Pérdida del sentido del gusto / olfato.';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Dificultad al respirar';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Malestar estomacal / diarrea';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Vómitos / náuseas';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Ronchas o Alergias en la Piel';
// lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = '¿Qué tanto te costó o te resulta respirar? (2 = dificultad para respirar leve, 8 = dificultad para respirar severa)';
// lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = '¿Qué tan mal te sentiste o te sentiste en general? (2 = solo levemente enfermo, 8 = gravemente enfermo / fue al hospital)';
// lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = '¿Qué tan ansioso estaba / estás por COVID19? (2 = un poco ansioso, 8 = muy ansioso)';
// lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = '¿Cuándo experimentaste los primeros síntomas?';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = '¿Durante cuánto tiempo se sintió enfermo / tuvo síntomas?';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Unos días o menos';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Menos de una semana';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Una o dos semanas';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Dos o tres semanas';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Más de tres semanas';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Todavía estoy enfermo!';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = '¿Creía que estaba infectado con Coronavirus / COVID-19?';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'De ninguna manera';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Una pequeña posibilidad (~ 25% seguro)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Una oportunidad razonable (~ 50% seguro)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Bastante seguro (~ 75% seguro)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolutamente convencido (~ 90% + seguro)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Sí, 100%: he dado positivo';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Sí, 100%: me trataron en el hospital';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Sí, 100%: me trataron en cuidados intensivos';
lang.YOURSYMPTOMS_PERSONAL_HISTORY =
  "¿Tiene algún antecedente personal o familiar que pueda ser relevante?";
lang.YOURSYMPTOMS_SYMPTOMS_SATRTFEELINGSICK =
  "¿Qué síntomas ha tenido y qué tan fuertes han sido desde que comenzó a sentirse enfermo?";

lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT =
  "¿Qué tan difícil te fue, o te resulta difícil respirar? (2 = dificultad para respirar leve, 8 = dificultad para respirar grave)";
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT =
  "¿Cuándo comenzaron sus síntomas?";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT =
  "¿Durante cuánto tiempo se sintió enfermo/tuvo síntomas?";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = "Unos días o menos";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK =
  "Menos de una semana";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = "Una a dos semanas";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS =
  "Dos a tres semanas";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS =
  "Más de tres semanas";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = "Sigo enfermo";

lang.COVIDTESTING_PAGE_TITLE =
  "Adicionales libre ~ 3 min para completar el cuestionario";
lang.COVIDTESTING_IDEAABOUT_TEXT =
  "¿Tienes alguna idea de lo que podría estar pasando?";
lang.COVIDTESTING_CONCERNING_TEXT =
  "¿Hay algo que le preocupe en este momento?";
lang.COVIDTESTING_DISCUSSION_TEXT =
  "¿Había algo que esperabas de nuestra conversación de hoy?";

lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT =
  "¿Se ha hecho una prueba de anticuerpos para verificar si tiene / tuvo COVID-19?";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO =
  "No, no he hecho una prueba de anticuerpos.";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST =
  "No, pero hice un tipo diferente de prueba";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = "Sí, y salí positivo";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE =
  "Sí, pero obtuve un resultado negativo.";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW =
  "Sí, pero no sé el resultado.";
// lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = '¿En qué fecha hiciste la prueba?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT =
  "¿Qué tipo de prueba de anticuerpos realizó para ver si tenía / tuvo COVID-19?";
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY =
  "Análisis rápido de sangre de anticuerpos (inmunoensayo de flujo lateral)";
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST =
  "Envío de sangre para análisis de anticuerpos de laboratorio.";
lang.ANTIBODYTEST_PAGE_TITLE = "Prueba de anticuerpos";
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = '¿Cómo se veía el resultado de su prueba?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = "Solo C";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = "C e IgG";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = "C e IgM";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = "C, IgM e IgG";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = "Solo IgG (sin C)";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = "Solo IgM (sin C)";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM =
  "IgG e IgM solamente (sin C)";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = "Ninguno, sin líneas";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE =
  "Ninguno de las anteriores";
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT =
  "Cargue una foto de la prueba de anticuerpos tomada directamente desde arriba, con una iluminación uniforme para que el casete de prueba llene la pantalla (como la siguiente).";
lang.OTHERTESTS_PAGE_TITLE = "Otras pruebas";
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT =
  "¿Envió sangre para una prueba de laboratorio de anticuerpos?";
lang.OTHERTESTS_SWABTESTQUESTION_TEXT =
  "¿Ha realizado una muestra de esputo / nasal / garganta para PCR?";
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT =
  "¿Has hecho una prueba de antígeno viral?";
lang.OTHERTESTS_XRAYQUESTION_TEXT = "¿Le han hecho una prueba de rayos X / CT?";
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT =
  "¿Has tenido un diagnóstico médico?";
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = "No hizo";

lang.EMAILCONSENT_PAGE_TITLE = "Ultima Pregunta";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT =
  "¿Le interesaría saber acerca de algún estudio, programa de prueba o ensayo para ayudar a confirmar si se ha recuperado por completo, para donar plasma o si es inmune al Coronavirus?";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO =
  "Interesado, envíeme más información cuando se entere";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO =
  "Interesado, pero no me manden información por favor";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED =
  "No, no estoy interesado";
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT =
  "Por favor deje su dirección de correo electrónico a continuación";
lang.RESULT_PAGE_TITLE = "Resultados";
lang.RESULT_PAGE_TEXT = "You will receive your results by email shortly.";

// AQ model translater
lang.ANSWER_INSTRUCTIONS_TITLE = "INSTRUCCIONES";
lang.ANSWER_INSTRUCTIONS_PARA =
  "Para optimizar el rendimiento de esta aplicación, se recomienda que se someta a un examen médico antes de su uso. Si ya realizó la prueba o el examen y obtuvo los resultados y cualquier posible diagnóstico, asegúrese de tenerlos disponibles.";
lang.ANSWER_CONFIDENTIALITY_TITLE = "AVISO DE CONFIDENCIALIDAD";
lang.ANSWER_CONFIDENTIALITY_PARA1 =
  "Tenga en cuenta que un equipo de profesionales médicos ha desarrollado esta aplicación. Le aseguramos que cualquier información de identificación personal que proporcione siempre será tratada con la máxima confidencialidad. Bajo ninguna circunstancia se compartirá, venderá o divulgará su información a terceros sin su permiso explícito.";
lang.ANSWER_CONFIDENTIALITY_PARA2 =
  "El objetivo principal de esta aplicación es ayudar a los investigadores a obtener información y mejorar la comprensión del viaje del paciente para las personas.";
lang.ANSWER_CONFIDENTIALITY_PARA3 =
  "Para más detalles o consultas, comuníquese con nosotros a: privacy@vibesdoc.com";

// clicking logo model translator
lang.LOGO_CLICK_PARA1 =
  "Bienvenido a Vibesdoc, una revolucionaria plataforma de atención médica que se enfoca en mejorar la salud del paciente priorizando el bienestar";
lang.LOGO_CLICK_PARA2 =
  "En Vibesdoc, entendemos que la buena salud no se trata solo de tratar enfermedades, sino también de tomar medidas proactivas hacia un estilo de vida saludable. Es por eso que capacitamos a nuestros pacientes para que se conecten y chateen con profesionales calificados de su elección, para que puedan revisarse y evaluarse para el alivio inmediato de cualquier condición que puedan estar experimentando. Nuestra plataforma fácil de usar permite a los pacientes mantener fácilmente su salud duradera al realizar cambios en el estilo de vida que respaldan su bienestar. Nuestro objetivo es proporcionar un enfoque integral y holístico de la atención médica que priorice el bienestar del paciente.";
lang.LOGO_CLICK_PARA3 =
  "Da los pasos hacia una vida más sana y feliz con Vibesdoc.";
lang.LOGO_CLOSE = "Cerrar";

// FAQ translator
lang.FAQ_HEADING = "Preguntas más frecuentes";
lang.FAQ_PARA1 =
  "Para obtener información general sobre el coronavirus y el COVID-19, siga";
lang.FAQ_LINK = "Directrices de los CDC";
lang.FAQ_ABOUT_TITLE = "Sobre el Cuestionario";
lang.FAQ_ABOUT_QUES1 = "¿Por qué es tan largo el cuestionario?";
lang.FAQ_ABOUT_ANS1 =
  "Nuestro software aprovecha varias fuentes de datos para brindar la predicción más confiable; por lo tanto, se requiere la información más completa y exhaustiva para una predicción precisa.";
lang.FAQ_ABOUT_QUES2 = "¿Tengo que responder a todas las preguntas?";
lang.FAQ_ABOUT_ANS2 =
  "¡SÍ! Sabemos que es mucho, pero la información completa permitirá que el algoritmo prediga su posible exposición y riesgo de manera más confiable.";
lang.FAQ_ABOUT_QUES3 =
  "No realicé ninguna prueba. ¿Todavía puedo usar su cuestionario?";
lang.FAQ_ABOUT_ANS3 =
  "Sí tu puedes. Nuestro cuestionario funciona mucho mejor cuando se combina con una prueba; sin embargo, el algoritmo aún puede predecir con cierta precisión si su caso podría correlacionarse con una enfermedad en particular.";
lang.FAQ_ABOUT_QUES4 =
  "¿Por qué necesito tomar el cuestionario? ¿No será suficiente una prueba?";
lang.FAQ_ABOUT_ANS4 =
  "Aunque las pruebas son razonablemente precisas, si se aplican a una población con una baja tasa de enfermedad, un resultado positivo no es muy predictivo. El cuestionario evalúa su riesgo de enfermedad y la prueba puede confirmar o invalidar (negar) la predicción. Piense en ello como tener una segunda opinión sobre un diagnóstico médico. Si dos médicos están de acuerdo de forma independiente, puede tener mucha más confianza que si no están de acuerdo.";
lang.FAQ_DATAPRIVACY_TITLE = "Privacidad de datos";
lang.FAQ_DATAPRIVACY_QUES1 = "¿Mis resultados son públicos?";
lang.FAQ_DATAPRIVACY_ANS1 =
  "No, sus resultados no son públicos. Sus resultados de identificación personal no se compartirán con nadie (incluidos investigadores, organizaciones o gobiernos) fuera de Silibrain LLC. Silibrain LLC mantiene estos datos solo para referencia con nuestros médicos bajo las políticas de privacidad más estrictas como con los datos de nuestros pacientes. Usted conserva todo el derecho a que sus respuestas se eliminen inmediatamente de nuestros registros de pacientes y, por lo tanto, automáticamente de todos nuestros conjuntos de datos de investigación no identificados en cualquier momento poniéndose en contacto con privacy@vibesdoc.com.";
lang.FAQ_DATAPRIVACY_QUES2 =
  "¿Qué pasará con mis datos? ¿Cómo se almacena y distribuye?";
lang.FAQ_DATAPRIVACY_ANS2 =
  "Sus datos desidentificados/anonimizados (no hay forma posible de conectar los datos con usted) se utilizarán con fines de investigación. Sus datos se almacenan con los más altos estándares de seguridad de acuerdo con HIPAA y GDPR.";
lang.FAQ_DATAPRIVACY_QUES3 = "¿Mantienen mis datos privados?";
lang.FAQ_DATAPRIVACY_ANS3 =
  "Sus resultados de identificación personal no se compartirán con nadie (incluidos investigadores, organizaciones o gobiernos) fuera de Silibrain LLC. Silibrain LLC mantiene estos datos solo para referencia con nuestros médicos bajo las políticas de privacidad más estrictas como con los datos de nuestros pacientes. Usted conserva todo el derecho a que sus respuestas se eliminen inmediatamente de nuestros registros de pacientes y, por lo tanto, automáticamente de todos nuestros conjuntos de datos de investigación no identificados en cualquier momento poniéndose en contacto con privacy@vibesdoc.com.";
lang.FAQ_RESULTS_TITLE = "Mis resultados";
lang.FAQ_RESULTS_QUES1 = "¿Dónde puedo encontrar mis resultados?";
lang.FAQ_RESULTS_ANS1 =
  "Su resultado solo está disponible después de completar la prueba; no almacenaremos el resultado vinculado a ninguna dirección de correo electrónico o nombre en este momento, por lo que no podremos recuperarlo específicamente.";
lang.FAQ_RESULTS_QUES2 = "¿Un profesional médico revisa mi resultado?";
lang.FAQ_RESULTS_ANS2 =
  "No. Varios profesionales médicos han revisado el proceso, pero un profesional médico no revisa cada resultado de forma independiente.";
lang.FAQ_RESULTS_QUES3 =
  "Tengo el resultado de mi cuestionario. ¿Todavía tengo que hacerme un examen médico?";
lang.FAQ_RESULTS_ANS3 =
  "Suponga que solo tomó el cuestionario y obtuvo un resultado positivo o negativo. En ese caso, puede hacer que el diagnóstico sea mucho más preciso haciéndose un examen médico y haciendo un seguimiento con un profesional médico. No recomendamos confiar solo en la aplicación.";
lang.FAQ_RESULTS_QUES4 = "¿Qué significa mi resultado?";
lang.FAQ_RESULTS_ANS4 =
  "Debería haber visto un resultado personal explicable detallado si ha completado el cuestionario y la prueba. Los resultados más probables son que la prueba o el algoritmo concuerden con el diagnóstico. En este caso, puede estar seguro de que los resultados probablemente serán correctos. Si la prueba y el algoritmo no están de acuerdo, esto puede significar que el resultado de la prueba fue un falso positivo o negativo. En este escenario, recomendamos repetir la prueba y hacer un seguimiento con un profesional médico.";
lang.FAQ_RESULTS_QUES5 =
  "Mi resultado fue negativo. ¿Significa que no estaba enfermo?";
lang.FAQ_RESULTS_ANS5 =
  "Si el algoritmo y la prueba dieron un resultado negativo, es poco probable que haya estado enfermo. Es posible pero poco probable que estuviera enfermo, que no mostrara los síntomas típicos u otros indicadores y que no desarrollara una fuerte respuesta de anticuerpos. En este caso inusual, asumir que no ha estado enfermo puede ser más seguro de todos modos.";
lang.FAQ_RESULTS_QUES6 = "Mi resultado fue positivo. ¿Qué significa?";
lang.FAQ_RESULTS_ANS6 =
  "Es probable que se haya enfermado si el algoritmo y la tira reactiva dieran un resultado positivo. No hay evidencia suficiente para sugerir que la falta de antecedentes familiares, síntomas específicos, estilo de vida o preferencias de vacunación eliminen el riesgo de contraer una enfermedad. Se están realizando muchos estudios sobre la inmunidad/protección proporcionada por los anticuerpos, y esta respuesta se actualizará cuando surja nueva evidencia.";
lang.FAQ_ALGORITHM_TITLE = "Algoritmo";
lang.FAQ_ALGORITHM_QUES1 = "¿Cómo funciona el algoritmo de predicción?";
lang.FAQ_ALGORITHM_ANS1 =
  "La API ChatGPT de OpenAI se utiliza para absorber las respuestas de un cuestionario del paciente y correlacionarlas con enfermedades potenciales siguiendo un enfoque estructurado. Aquí hay un esquema general del proceso:";
lang.FAQ_ALGORITHM_ANS2 =
  "Se prepara un cuestionario completo que cubre síntomas relevantes, historial médico, factores de estilo de vida y cualquier otra información pertinente relacionada con enfermedades potenciales.";
lang.FAQ_ALGORITHM_ANS3 =
  "El cuestionario se presenta al paciente a través de un formulario web o una interfaz conversacional impulsada por ChatGPT. El paciente respondería cada pregunta, que podría ser entradas de texto o seleccionar opciones predefinidas.";
lang.FAQ_ALGORITHM_ANS4 =
  "Una vez que el paciente completa el cuestionario, las respuestas deben ser procesadas y validadas. Esto implica verificar que estén completos, garantizar que las respuestas estén dentro de los rangos o formatos esperados y manejar las respuestas faltantes o ambiguas.";
lang.FAQ_ALGORITHM_ANS5 =
  "To correlate the responses with potential diseases, it is essential to convert the answers into a structured format that the AI model can process. This involves creating a standardised representation of symptoms, encoding medical conditions, and assigning numerical values to certain factors.";
lang.FAQ_ALGORITHM_ANS6 =
  'Preguntas como, "Basado en los síntomas descritos, ¿cuáles son las enfermedades potenciales y sus probabilidades?" están formulados para utilizar la API de ChatGPT para consultar el modelo de IA con los datos estructurados derivados de las respuestas del paciente.';
lang.FAQ_ALGORITHM_ANS7 =
  "La información relevante se analiza y extrae para utilizar mejor el modelo. El modelo genera una lista de enfermedades potenciales y probabilidades asociadas para cada condición en función de los síntomas y factores proporcionados.";
lang.FAQ_ALGORITHM_ANS8 =
  "Los resultados se presentan y muestran al paciente de forma clara y clara.";
lang.FAQ_ALGORITHM_ANS9 =
  "Junto con los resultados, se puede proporcionar un contexto adicional, como las limitaciones del análisis, los sesgos potenciales y las recomendaciones para una evaluación médica adicional o la búsqueda de asesoramiento profesional.";
lang.FAQ_ALGORITHM_ANS10 =
  "El cuestionario debe refinarse continuamente, y el procesamiento de datos y las interacciones del modelo se basan en la retroalimentación y la evaluación. Este proceso iterativo ayuda a mejorar la precisión y la eficacia del sistema a lo largo del tiempo.";
lang.FAQ_ALGORITHM_ANS11 =
  "Es importante tener en cuenta que, si bien ChatGPT puede proporcionar información y posibles correlaciones, no debe considerarse un sustituto del diagnóstico o el asesoramiento médico profesional. Siempre consulte a profesionales de la salud calificados para obtener evaluaciones y recomendaciones precisas sobre problemas de salud.";
lang.FAQ_RECOMENDATION_TITLE = "Recomendaciones";
lang.FAQ_RECOMENDATION_QUES1 = "¿Puedo compartir mis resultados?";
lang.FAQ_RECOMENDATION_ANS1 =
  "Si desea compartir sus resultados, es libre de hacerlo. Si desea compartir el sitio web y recomendar a amigos y familiares que lo prueben, se lo agradeceríamos mucho.";
lang.FAQ_RECOMENDATION_QUES2 =
  "¿Puedo usar mis resultados como prueba de inmunidad?";
lang.FAQ_RECOMENDATION_ANS2 =
  "No, estos resultados no se aceptan actualmente como prueba de inmunidad. Recomendamos seguimiento con un profesional médico.";
lang.FAQ_RECOMENDATION_QUES3 =
  "¿Puedo volver a la vida cotidiana si los resultados son negativos?";
lang.FAQ_RECOMENDATION_ANS3 =
  "Debe ponerse en contacto con su médico y seguir sus recomendaciones sobre los próximos pasos adecuados. Es su decisión cómo usar esta información. Sin embargo, le sugerimos enfáticamente que considere su riesgo y el de los que lo rodean.";

export default lang;
