import key from './key';
import en from './en';
import es from './es';
// import ru from './ru';
// import de_ from './de_';
// import it_ from './it_';
// import pt from './pt';
// import fr_ from './fr_';
// import ar__ from './ar__';
// import hi__ from './hi__';
// // import zh__ from './zh__';
// import bn__ from './bn__';
// import id__ from './id__';
// import ja__ from './ja__';
// import fa from './fa';
// import tr_ from './tr_';
// import ur__ from './ur__';
// import nl__ from './nl__';
// import be_ from './be_';
// import ko_ from './ko_';
// import ca_ from './ca_';
// import cs_ from './cs_';
import { I18N } from './i18n';

export interface IDefaultExports {
  key: I18N;
  en: I18N;
  es: I18N;
  // ru: I18N;
  // de_: I18N;
  // it_: I18N;
  // pt: I18N;
  // fr_: I18N;
  // ar__: I18N;
  // hi__: I18N;
  // zh__: I18N;
  // bn__: I18N;
  // id__: I18N;
  // ja__: I18N;
  // fa: I18N;
  // tr_: I18N;
  // ur__: I18N;
  // nl__: I18N;
  // be_: I18N;
  // ko_: I18N;
  // ca_: I18N;
  // cs_: I18N;
}

const defaultExports: IDefaultExports = {
  key,
  es,
  en,
  // ru,
  // de_,
  // it_,
  // pt,
  // fr_,
  // ar__,
  // hi__,
  // // zh__,
  // bn__,
  // id__,
  // ja__,
  // fa,
  // tr_,
  // ur__,
  // nl__,
  // be_,
  // ko_,
  // ca_,
  // cs_,
};

export default defaultExports;
