import * as React from "react";
import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import QuestionRadioButton from "../../../components/QuestionRadioButton";
import { translationContext } from "../../../services/TranslateService";
import {
  addQuestionResultActionCreator,
  GenerateNextPageAndSection,
  questionnaireContext,
  QuestionnairePagesPaths,
} from "../QuestionnaireState";
import QuestionCheckboxGroup from "../../../components/QuestionCheckboxGroup";
import Footer from "../../../components/Footer";
import HCCSFocusError from "../../../components/HCCSFocusError";
import { IExposeRiskState, TExposeRiskState } from "./ExposeRiskState";
import QuestionText from "../../../components/QuestionText";
import useCommonStyles from "../../commonStyles";
import useScrollToTheTop from "../../../hooks/useScrollToTheTop";
import BackNextNavigation from "../../../components/BackNextNavigation";
import storage from "../../../services/storage.service";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const exposeRiskNext: GenerateNextPageAndSection<IExposeRiskState> = (
  values
) => {
  // if user mark that his family is not ill we will skip IllHousemates section
  const nextPage =
    values.peopleLive === "0"
      ? QuestionnairePagesPaths.wereYouIll
      : QuestionnairePagesPaths.illHousemates;
  return { nextPage };
};

const ExposeRisk: React.FunctionComponent = () => {
  const [t] = useContext(translationContext);
  const [questionnaireState, dispatch] = useContext(questionnaireContext);
  const history = useHistory();
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  useScrollToTheTop();

  const didWorkItems = [
    { label: t.EXPOSURERISK_INDUSTRYWORK_AGRICULTURE, value: "0" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_UTILITIES, value: "1" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_FINANCE, value: "2" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_ENTERTAINMENT, value: "3" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_EDUCATION, value: "4" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_HEALTHCARE, value: "5" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_IT, value: "6" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_HOSPITALITY, value: "7" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_LEGAL, value: "8" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_MARKETING, value: "9" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_MILITARY, value: "10" },
    { label: t.EXPOSURERISK_INDUSTRYWORK_PREFERNOTSAY, value: "11" },
  ];
  const diagnosedItems = [
    { label: t.EXPOSURERISK_DIAGNOSED_HEART, value: "heartDisease" },
    { label: t.EXPOSURERISK_DIAGNOSED_DIABETES, value: "diabetes" },
    { label: t.EXPOSURERISK_DIAGNOSED_HIGHBLOOD, value: "highBlood" },
    {
      label: t.EXPOSURERISK_DIAGNOSED_HIGHCHOLESTEROL,
      value: "highCholesterol",
    },
    { label: t.EXPOSURERISK_DIAGNOSED_CANCER, value: "cancer" },
    { label: t.EXPOSURERISK_DIAGNOSED_STROKE, value: "stroke" },
    { label: t.EXPOSURERISK_DIAGNOSED_MENTALILLNESS, value: "mental" },
    {
      label: t.EXPOSURERISK_DIAGNOSED_NEURODEGENERATIVE,
      value: "neurodegenerativeDisease",
    },
    { label: t.EXPOSURERISK_DIAGNOSED_NONE, value: "none" },
  ];
  const consumeItems = [
    { label: t.EXPOSURERISK_YES_PRECRIBED, value: "yesPrecribed" },
    { label: t.EXPOSURERISK_YES_HERBAL, value: "yesHerbal" },
    { label: t.EXPOSURERISK_YES_COUNTER, value: "yesCounter" },
    { label: t.EXPOSURERISK_YES_RECREATIONAL, value: "yesRecreational" },
    { label: t.EXPOSURERISK_CONSUMEDRUGS_NONE, value: "none" },
  ];
  const alcoholItems = [
    { label: t.EXPOSURERISK_ALCOHOL_NONE, value: "0" },
    { label: t.EXPOSURERISK_ALCOHOL_ONE, value: "1" },
    { label: t.EXPOSURERISK_ALCOHOL_TWO, value: "2" },
  ];
  const smokeItems = [
    { label: t.EXPOSURERISK_SMOKE_NONE, value: "0" },
    { label: t.EXPOSURERISK_SMOKE_ONE, value: "1" },
    { label: t.EXPOSURERISK_SMOKE_TWO, value: "2" },
  ];
  const peopelItems = [
    { label: t.EXPOSURERISK_PEOPLE_NONE, value: "0" },
    { label: t.EXPOSURERISK_PEOPLE_ONE, value: "1" },
    { label: t.EXPOSURERISK_PEOPLE_TWO, value: "2" },
  ];
  const petItems = [
    { label: t.EXPOSURERISK_PETHOME_NONE, value: "0" },
    { label: t.EXPOSURERISK_PETHOME_ONE, value: "1" },
    { label: t.EXPOSURERISK_PETHOME_TWO, value: "2" },
  ];

  const handleFormSubmit = (values: any) => {
    // Add the current form values (questions and answers) to the array
    const responseData = storage.getObject("questionAnswer") as {
      answers: Array<string>;
      questions: Array<string>;
    };
    console.log("abc", responseData.questions);
    console.log("abc", responseData.answers);
    const expore = {
      questions: [
        ...(responseData.questions ?? []),
        t.EXPOSURERISK_DIAGNOSED_TEXT,
        t.EXPOSURERISK_INDUSTRYWORK_TEXT,
        t.EXPOSURERISK_CONSUMEDRUGS_TEXT,
        t.EXPOSURERISK_SMOKE_TEXT,
        t.EXPOSURERISK_ALCOHOL_TEXT,
        t.EXPOSURERISK_PEOPLE_TEXT,
        t.EXPOSURERISK_PETHOME_TEXT,
      ],

      answers: [
        ...(responseData.answers ?? []),
        values.diagnosed.join(","),
        didWorkItems[values.didWork].label,
        values.consumeDrugs.join(","),
        smokeItems[values.smoke].label,
        alcoholItems[values.alcohol].label,
        peopelItems[values.peopleLive].label,
        petItems[values.pet].label,
      ],
    };
    console.log("abc", expore);

    storage.setObject("questionAnswer", expore);
    const { nextPage } = exposeRiskNext(values);

    dispatch(
      addQuestionResultActionCreator({
        key: "exposeRisk",
        value: values,
        activePage: nextPage,
      })
    );

    history.push(nextPage);
  };
  return (
    <>
      <h3 className={commonStyles.textCenter} style={{ color: "#956DF7" }}>
        {t.EXPOSURERISK_PAGE_TITLE}
      </h3>
      <Formik
        initialValues={questionnaireState.exposeRisk.values}
        validationSchema={Yup.object({
          didWork: Yup.string().required("Required"),
          diagnosed: Yup.array().required("Required"),
          consumeDrugs: Yup.string().required("Required"),
          smoke: Yup.string().required("Required"),
          alcohol: Yup.string().required("Required"),
          peopleLive: Yup.string().required("Required"),
          pet: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleFormSubmit(values);
        }}>
        {(values: TExposeRiskState) => (
          <Form className={classes.root}>
            <QuestionCheckboxGroup
              name="diagnosed"
              options={diagnosedItems}
              values={values.values.diagnosed}
              errors={values.errors.diagnosed}
              isTouched={values.getFieldMeta("diagnosed")?.touched}
              questionText={t.EXPOSURERISK_DIAGNOSED_TEXT}
              hasClearOption
            />

            <QuestionRadioButton
              name="didWork"
              items={didWorkItems}
              questionText={t.EXPOSURERISK_INDUSTRYWORK_TEXT}
            />
            <QuestionCheckboxGroup
              name="consumeDrugs"
              options={consumeItems}
              values={values.values.consumeDrugs}
              errors={values.errors.consumeDrugs}
              isTouched={values.getFieldMeta("consumeDrugs")?.touched}
              questionText={t.EXPOSURERISK_CONSUMEDRUGS_TEXT}
              hasClearOption
            />
            <QuestionRadioButton
              name="smoke"
              items={smokeItems}
              questionText={t.EXPOSURERISK_SMOKE_TEXT}
            />
            <QuestionRadioButton
              name="alcohol"
              items={alcoholItems}
              questionText={t.EXPOSURERISK_ALCOHOL_TEXT}
            />
            <QuestionRadioButton
              name="peopleLive"
              items={peopelItems}
              questionText={t.EXPOSURERISK_PEOPLE_TEXT}
            />
            <QuestionRadioButton
              name="pet"
              items={petItems}
              questionText={t.EXPOSURERISK_PETHOME_TEXT}
            />
            <HCCSFocusError />
            <BackNextNavigation nextButtonText={t.GLOBAL_LABEL_NEXT} />
          </Form>
        )}
      </Formik>
      <Hidden smDown>
        <Footer />
      </Hidden>
    </>
  );
};

export default ExposeRisk;
