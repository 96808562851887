/* eslint-disable @typescript-eslint/quotes */
import * as React from "react";
import { useContext } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { translationContext } from "../../../services/TranslateService";
import QuestionRadioButton from "../../../components/QuestionRadioButton";
import QuestionText from "../../../components/QuestionText";
import QuestionSelect from "../../../components/QuestionSelect";
import {
  addQuestionResultActionCreator,
  GenerateNextPageAndSection,
  questionnaireContext,
  QuestionnairePagesPaths,
} from "../QuestionnaireState";
import Footer from "../../../components/Footer";
import HCCSFocusError from "../../../components/HCCSFocusError";
import countries, {
  usaRegions,
} from "../../../services/countries-translate.service";
import useCommonStyles from "../../commonStyles";
import useScrollToTheTop from "../../../hooks/useScrollToTheTop";
import { ILocationAndTravelState } from "./LocationAndTravelState";
import BackNextNavigation from "../../../components/BackNextNavigation";
import Axios from "axios";
import { CircularProgress } from "@material-ui/core";
import storage from "../../../services/storage.service";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const locationAndTravelNext: GenerateNextPageAndSection<
  ILocationAndTravelState
> = (values) => {
  // if user did not visit other country we will skip Travel and Continue section
  const nextPage =
    values.travelledToOtherCountries === "0"
      ? QuestionnairePagesPaths.travelContinued
      : QuestionnairePagesPaths.exposeRisk;
  return { nextPage };
};

const LocationAndTravel = () => {
  const [t, , currentLang] = useContext(translationContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [questionnaireState, dispatch] = useContext(questionnaireContext);
  const history = useHistory();
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  useScrollToTheTop();

  const [locationItems, setLocationItems] = React.useState([]);
  const [regions, setRegions] = React.useState([]);

  const selectedCountry = React.useRef(null);

  // const fetchCountryData = (locationItems: any) => {
  //   console.log(locationItems);
  // }

  React.useEffect(() => {
    const countryData = [
      {
        label: "United States",
        value: "United States",
      },
      {
        label: "Guatemala",
        value: "Guatemala",
      },
      {
        label: "Belize",
        value: "Belize",
      },
      {
        label: "Honduras",
        value: "Honduras",
      },
      {
        label: "Guyana",
        value: "Guyana",
      },
      {
        label: "Paraguay",
        value: "Paraguay",
      },
    ];
    setLocationItems(countryData);
  }, []);

  const anyOtherCountriesItems = [
    // {
    //   label: t.GLOBAL_LABEL_NO,
    //   value: "0",
    // },
    {
      label: t.LOCATIONANDTRAVEL_TRAVELQUESTION_YES,
      value: "0",
    },
    {
      label: t.LOCATIONANDTRAVEL_TRAVELQUESTION_NO,
      value: "1",
    },
    // {
    //   label: t.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN,
    //   value: "3",
    // },
  ];

  const threeCharacters = /^.{3}$/;
  const clearRegion = (
    values: ILocationAndTravelState,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ): null => {
    if (values.region) {
      setFieldValue("region", "");
    }
    return null;
  };

  const fetchRegion = (values: ILocationAndTravelState) => {
    if (selectedCountry.current !== values.country) {
      selectedCountry.current = values.country;
      console.log("test value", values);
      const obj = {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: `Please provide an array of objects containing the names of states in the ${selectedCountry.current}. Each object should have a key-value structure with the keys 'label' and 'value' both containing the state name.`,
          },
        ],
      };
      setIsLoading(true);
      Axios.post(`https://api.openai.com/v1/chat/completions`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer sk-proj-XtDgkYFCsdQJ24v5xsNUT3BlbkFJXlGRgAaOZj05Ps2N2Ycz",
        },
      })
        .then((res) => {
          const content = res?.data?.choices?.[0]?.message?.content;
          console.log("response from open ai", content);

          setRegions(JSON.parse(content));
          console.log("response from open ai", JSON.parse(content));
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  // console.log("old question", responseData);
  const handleFormSubmit = async (values: any) => {
    // Add the current form values (questions and answers) to the array
    const responseData = (await storage.getObject("questionAnswer")) as {
      answers: Array<string>;
      questions: Array<string>;
    };

    console.log("abc", responseData.questions);
    console.log("abc", responseData.answers);

    const locationTravel = {
      questions: [
        ...(responseData.questions ?? []),
        t.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT,
        t.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT,
        t.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT,
        t.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT,
      ],

      answers: [
        ...(responseData.answers ?? []),
        values.country,
        values.region,
        values.postcode,
        anyOtherCountriesItems[values.travelledToOtherCountries].label,
      ],
    };
    console.log("abc", locationTravel);

    storage.setObject("questionAnswer", locationTravel);
    const { nextPage } = locationAndTravelNext(values);

    dispatch(
      addQuestionResultActionCreator({
        key: "locationAndTravel",
        value: values,
        activePage: nextPage,
      })
    );

    history.push(nextPage);
  };

  return (
    <>
      <h3
        style={{ color: "#956DF7 !important" }}
        className={commonStyles.textCenter}>
        {t.LOCATIONANDTRAVEL_PAGE_TITLE}
      </h3>
      <Formik
        initialValues={questionnaireState.locationAndTravel.values}
        validationSchema={Yup.object({
          country: Yup.string().required(t.GLOBAL_FORMVALIDATION_REQUIRED),
          region: Yup.string().notRequired(),
          postcode: Yup.string()
            .required(t.GLOBAL_FORMVALIDATION_REQUIRED)
            .matches(
              threeCharacters,
              t.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS
            ),
          travelledToOtherCountries: Yup.string().required(
            t.GLOBAL_FORMVALIDATION_REQUIRED
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // storage.setObject("questionAnswer", locationTravel);

          setSubmitting(false);
          handleFormSubmit(values);
        }}>
        {({ values, setFieldValue }) => (
          <Form className={classes.root}>
            <QuestionSelect
              name="country"
              questionText={t.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT}
              items={locationItems}
            />
            {!!values.country && fetchRegion(values)}
            {isLoading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {values.country ? (
                  <>
                    {regions.length > 0 ? (
                      <QuestionSelect
                        name="region"
                        questionText={t.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT}
                        items={regions}
                      />
                    ) : null}
                  </>
                ) : (
                  clearRegion(values, setFieldValue)
                )}
              </>
            )}
            <QuestionText
              name="postcode"
              questionText={t.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT}
            />
            <QuestionRadioButton
              name="travelledToOtherCountries"
              questionText={t.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT}
              items={anyOtherCountriesItems}
            />
            <HCCSFocusError />
            <BackNextNavigation nextButtonText={t.GLOBAL_LABEL_NEXT} />
          </Form>
        )}
      </Formik>
      <Hidden smDown>
        <Footer />
      </Hidden>
    </>
  );
};

export default LocationAndTravel;
