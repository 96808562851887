import * as React from "react";
import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import useGlobalCommonStyles from "../useGlobalCommonStyles";
import { translationContext } from "../services/TranslateService";

const hStyle = {
  color: "black",
};

const qStyle = {
  fontWeight: "bold" as "bold",
};

const pStyle = {
  color: "#ae50f9",
};

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    // position: "absolute",
    // bottom: "0",
    // left: "0",
    width: "100%",
    padding: "0.3rem 1rem",
    // padding: '0.3rem 3rem',
    // background: theme.palette.background.default,
    color: theme.palette.common.white,
    fontSize: "12px",
  },
  faq: {
    "&:hover": {
      cursor: "pointer",
      filter: "drop-shadow(0 0 0.3rem silver)",
      transition: "filter 0s",
    },
  },
}));

const Footer: React.FunctionComponent = () => {
  const [t] = useContext(translationContext);
  const classes = useStyles();
  const globalClasses = useGlobalCommonStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* eslint-disable */
  const body = (
    <div className={globalClasses.modalBody}>
      <h2 style={hStyle}>{t.FAQ_HEADING}</h2>
      <p style={pStyle}>
        {t.FAQ_PARA1}
        <a
          href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html"
          target="blank"
          style={{ color: "#ef97fd" }}>
          {" "}
          {t.FAQ_LINK}{" "}
        </a>
      </p>
      <h4 style={hStyle}>{t.FAQ_ABOUT_TITLE}</h4>
      <p style={qStyle}>{t.FAQ_ABOUT_QUES1} </p>
      <p style={pStyle}>{t.FAQ_ABOUT_ANS1}</p>
      <p style={qStyle}>{t.FAQ_ABOUT_QUES2}</p>
      <p style={pStyle}>{t.FAQ_ABOUT_ANS2}</p>
      <p style={qStyle}>{t.FAQ_ABOUT_QUES3}</p>
      <p style={pStyle}>{t.FAQ_ABOUT_ANS3}</p>
      <p style={qStyle}>{t.FAQ_ABOUT_QUES4}</p>
      <p style={pStyle}>{t.FAQ_ABOUT_ANS4}</p>
      <h4 style={hStyle}>{t.FAQ_DATAPRIVACY_TITLE} </h4>
      <p style={qStyle}>{t.FAQ_DATAPRIVACY_QUES1}</p>
      <p style={pStyle}>{t.FAQ_DATAPRIVACY_ANS1}</p>
      <p style={qStyle}>{t.FAQ_DATAPRIVACY_QUES2} </p>
      <p style={pStyle}>{t.FAQ_DATAPRIVACY_ANS2}</p>
      <p style={qStyle}>{t.FAQ_DATAPRIVACY_QUES3}</p>
      <p style={pStyle}>{t.FAQ_DATAPRIVACY_ANS3}</p>

      <h4 style={hStyle}>{t.FAQ_RESULTS_TITLE} </h4>
      <p style={qStyle}>{t.FAQ_RESULTS_QUES1}</p>
      <p style={pStyle}>{t.FAQ_RESULTS_ANS1}</p>
      <p style={qStyle}>{t.FAQ_RESULTS_QUES2}</p>
      <p style={pStyle}>{t.FAQ_RESULTS_ANS2}</p>
      <p style={qStyle}>{t.FAQ_RESULTS_QUES3}</p>
      <p style={pStyle}>{t.FAQ_RESULTS_ANS3}</p>
      <p style={qStyle}>{t.FAQ_RESULTS_QUES4}</p>
      <p style={pStyle}>{t.FAQ_RESULTS_ANS4}</p>
      <p style={qStyle}>{t.FAQ_RESULTS_QUES5}</p>
      <p style={pStyle}>{t.FAQ_RESULTS_ANS5}</p>
      <p style={qStyle}>{t.FAQ_RESULTS_QUES6}</p>
      <p style={pStyle}>{t.FAQ_RESULTS_ANS6}</p>
      <h4 style={hStyle}>{t.FAQ_ALGORITHM_TITLE} </h4>
      <p style={qStyle}>{t.FAQ_ALGORITHM_QUES1}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS1}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS2}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS3}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS4}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS5}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS6}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS7}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS8}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS9}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS10}</p>
      <p style={pStyle}>{t.FAQ_ALGORITHM_ANS11}</p>

      <h4 style={hStyle}>{t.FAQ_RECOMENDATION_TITLE} </h4>
      <p style={qStyle}>{t.FAQ_RECOMENDATION_QUES1}</p>
      <p style={pStyle}>{t.FAQ_RECOMENDATION_ANS1}</p>
      <p style={qStyle}>{t.FAQ_RECOMENDATION_QUES2}</p>
      <p style={pStyle}>{t.FAQ_RECOMENDATION_ANS2}</p>
      <p style={qStyle}>{t.FAQ_RECOMENDATION_QUES3}</p>
      <p style={pStyle}>{t.FAQ_RECOMENDATION_ANS3}</p>
    </div>
  );
  /* es-lint-enable */
  return (
    <footer className={classes.footer}>
      <p style={pStyle}> 2023 Silibrain LLC I Todos los Derechos Reservados </p>
      <div
        onClick={handleOpen}
        onKeyDown={handleOpen}
        role="button"
        tabIndex={0}>
        <p className={classes.faq}>FAQ</p>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </footer>
  );
};

export default Footer;
