import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PersonIcon from "@material-ui/icons/Person";
// import CloseIcon from '@mui/icons-material/Close';
import CloseIcon from "@material-ui/icons/Close";
const DoctorModel = (props: any) => {
  const { open, setOpen, showSearch = false, speacility = "" } = props;
  const [doctor, setDoctor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(speacility);

  function handleRequest(keyword = "") {
    setIsLoading(true);
    const apiUrl = `https://chat.vibesdoc.com/default/get_users?keyword=${keyword}`;
    Axios.get(apiUrl)
      .then((response) => {
        console.log(response.data.data);
        setDoctor(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    handleRequest(searchQuery);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (searchData: string) => {
    handleRequest(searchData);
  };

  const navigateToWhatsapp = (whatsappLink: string) => {
    window.open(whatsappLink);
  };
  const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearch = debounce(handleSearch, 300);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogActions>
        {showSearch && (
          <TextField
            style={{ padding: "10px" }}
            placeholder="search doctor"
            variant="outlined"
            onChange={(e) => {
              debouncedSearch(e.target.value); // Call the debounced search function
            }}
          />
        )}
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogContent>
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {doctor?.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <DialogContentText>No search results found.</DialogContentText>
              </div>
            ) : (
              doctor?.map((ele) => (
                <Box
                  key={ele.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                    marginBottom: "15px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "10px",
                  }}>
                  {ele?.profile_image ? 
                    (
                      <img
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          border: "1px solid",
                          textAlign: "center",
                          marginTop: "4%",
                        }}
                        src={ele?.profile_image}
                        alt="profile emage" />
                   ) : (
                     <PersonIcon
                       style={{
                         width: 100,
                         height: 100,
                         borderRadius: "50%",
                         border: "1px solid",
                         textAlign: "center",
                         marginTop: "4%",
                       }}
                    />
                  )}
                  <Box
                    style={{
                      marginTop: "12px",
                      marginLeft: "10px",
                      width: "50%",
                    }}>
                    <DialogContentText style={{ fontSize: "14px" }}>
                      Name : {ele.name}
                    </DialogContentText>
                    <DialogContentText style={{ fontSize: "14px" }}>
                      Email : {ele.email}
                    </DialogContentText>
                    <DialogContentText style={{ fontSize: "14px" }}>
                      Speciality : {ele.speciality}
                    </DialogContentText>
                    <DialogContentText style={{ fontSize: "14px" }}>
                      Payment : 
                      {ele.payment_type_card.map((paymentType:any, index:any) => (
                      <span key={index}>
                        {paymentType}
                        {index !== ele?.payment_type_card?.length - 1 && paymentType !== "" ? ', ' : ''}
                      </span>
                    ))}
                    </DialogContentText>
                    <DialogContentText style={{ fontSize: "14px" }}>
                      Address : {ele?.address}
                    </DialogContentText>
                    <DialogContentText style={{ fontSize: "14px" }}>
                      Phone : {ele.phone}
                    </DialogContentText>
                  </Box>
                  <Button
                    style={{
                      height: "10%",
                      marginTop: "18%",
                      marginLeft: "5%",
                    }}
                    onClick={() => navigateToWhatsapp(ele.whatsappLink)}>
                    <WhatsAppIcon style={{ color: "green" }} />
                  </Button>
                </Box>
              ))
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DoctorModel;
