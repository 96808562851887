import { I18N } from "./i18n";

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = "Yes";
lang.GLOBAL_LABEL_NO = "No";
lang.GLOBAL_LABEL_DO_NOT_KNOW = "I don't know / can't remember";
lang.GLOBAL_LABEL_NEXT = "Next";
lang.GLOBAL_LABEL_BACK = "Back";
lang.GLOBAL_LABEL_NONE = "None";
lang.GLOBAL_LABEL_SUBMIT = "Submit";
lang.GLOBAL_FORMVALIDATION_REQUIRED = "Required";
lang.GLOBAL_FORMVALIDATION_EMAIL = "Please enter a valid email address";
lang.GLOBAL_ERROR_FORMSUBMISSION = "There was an error submitting the form.";
lang.MAINPAGE_PAGE_TITLE = "Hi, Welcome to Vibesdoc";
lang.MAINPAGE_PAGE_TEXT = "Please select from the options below:";
lang.MAINPAGE_PAGE_QUESTIONNAIRE = "Answer Questionnaire";
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = "Doctor Lists";
lang.MAINPAGE_PAGE_TEST_IMAGE = "Upload Test Image";
lang.GENERALINFORMATION_PAGE_TITLE =
  "General Information ~ 10 min to complete quiz";
lang.GENERALINFORMATION_GENDERQUESTION_TEXT =
  "What sex were you assigned at birth?";
lang.GENERALINFORMATION_GENDERQUESTION_MALE = "Male";
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = "Female";
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = "Other / Prefer not to say";
lang.GENERALINFORMATION_AGEQUESTION_TEXT = "What is your age group?";
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = "I prefer not to say";
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = "How is your health?";
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL =
  "I am very fit and rarely ill";
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER =
  "I am fitter and healthier than average";
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY =
  "I am less fit and healthy than average";
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY =
  "I'm not very healthy at all";
lang.GENERALINFORMATION_COMMONQUESTION_TEXT =
  "Which of the following do you have/apply to you?";
lang.GENERALINFORMATION_COMMONQUESTION_NONE = "None of the following";

lang.GENERALINFORMATION_COMMONQUESTION_MYOCARDIAL_INFARCTION =
  "Myocardial infarction";
lang.GENERALINFORMATION_COMMONQUESTION_JAUNDICE_LIVERDISEASE =
  "Jaundice and liver disease";
lang.GENERALINFORMATION_COMMONQUESTION_TB = "TB";
lang.GENERALINFORMATION_COMMONQUESTION_HIGN_BLOOD_PRESSURE =
  "High blood pressure";
lang.GENERALINFORMATION_COMMONQUESTION_RHEUMATOLOGY =
  "Rheumatology (i.e. skin or joint problems)";
lang.GENERALINFORMATION_COMMONQUESTION_EPILEPSY_SEIZURES =
  "Epilepsy or seizures";
lang.GENERALINFORMATION_COMMONQUESTION_ASTHMA_LUNG =
  "Asthma or other lung conditions";
lang.GENERALINFORMATION_COMMONQUESTION_DIABETES = "Diabetes";
lang.GENERALINFORMATION_COMMONQUESTION_STROKE_TIA = "Stroke or TIA";
lang.GENERALINFORMATION_COMMONQUESTION_STDS = "STDs";
lang.GENERALINFORMATION_COMMONQUESTION_ALLERGIES = "Allergies";

lang.GENERALINFORMATION_FLUQUESTION_TEXT =
  "To the best of your recolection, are you fully immunised for all communicable diseases?";

lang.LOCATIONANDTRAVEL_PAGE_TITLE =
  "Location and Travel ~ 8 min to complete quiz";
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = "Where are you living right now?";
lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT =
  "Please enter which state you live in.";
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT =
  "Please enter only the first three letters or numbers of your postcode/zipcode.";
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT =
  "Have you recently traveled to other countries?";
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_YES = "Yes";
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_NO = "No";
// lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Yes, after they came out of "lockdown"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS =
  "Please enter exactly 3 characters";
lang.TAKEPHOTO_PAGE_HEADER = "Take Photo Page";
lang.TAKEPHOTO_PAGE_BTN = "Take Photo";
lang.TRAVELCONTINUED_PAGE_TITLE = "Travel Continued ~ 7 min to complete quiz";
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT =
  "Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)";
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT =
  "What date did you enter that country?";
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT =
  "What date did you leave that country?";
lang.EXPOSURERISK_PAGE_TITLE = "Exposure risk ~ 7 min to complete quiz";
// lang.EXPOSURERISK_LINE_TEXT = 'What is your line of work?';
// lang.EXPOSURERISK_PRACTICESLIFE_DISEASES = 'Name a few of the practices In your daily life that you subscribe to avoid catching contagious diseases or developing chronic illness?';
// lang.EXPOSURERISK_ANYONE_POSSIBILESICK = 'Do you think anyone at home is possibly sick?';
// lang.EXPOSURERISK_ANYONE_POSSIBILESICK_YES = 'Yes';
// lang.EXPOSURERISK_ANYONE_POSSIBILESICK_NO = 'No';
// lang.EXPOSURERISK_ANYONE_ELABORATE = 'Elaborate on symptoms, and dates of your housemates, or descibe if not applicable.';
lang.EXPOSURERISK_INDUSTRYWORK_TEXT = "What industry do you work in?";
lang.EXPOSURERISK_INDUSTRYWORK_AGRICULTURE = "Agriculture";
lang.EXPOSURERISK_INDUSTRYWORK_UTILITIES = "Utilities";
lang.EXPOSURERISK_INDUSTRYWORK_FINANCE = "Finance";
lang.EXPOSURERISK_INDUSTRYWORK_ENTERTAINMENT = "Entertaintment";
lang.EXPOSURERISK_INDUSTRYWORK_EDUCATION = "Education";
lang.EXPOSURERISK_INDUSTRYWORK_HEALTHCARE = "Health care";
lang.EXPOSURERISK_INDUSTRYWORK_IT = "IT";
lang.EXPOSURERISK_INDUSTRYWORK_HOSPITALITY = "Hospitality";
lang.EXPOSURERISK_INDUSTRYWORK_LEGAL = "Legal";
lang.EXPOSURERISK_INDUSTRYWORK_MARKETING = "Marketing";
lang.EXPOSURERISK_INDUSTRYWORK_MILITARY = "Military";
lang.EXPOSURERISK_INDUSTRYWORK_PREFERNOTSAY = "Prefer not to say";

lang.EXPOSURERISK_DIAGNOSED_TEXT =
  "Has someone in your family been diagnosed with any of the following conditions?";
lang.EXPOSURERISK_DIAGNOSED_HEART = "Heart Disease";
lang.EXPOSURERISK_DIAGNOSED_DIABETES = "Diabetes";
lang.EXPOSURERISK_DIAGNOSED_HIGHBLOOD = "High Blood Pressure";
lang.EXPOSURERISK_DIAGNOSED_HIGHCHOLESTEROL = "High Cholesterol";
lang.EXPOSURERISK_DIAGNOSED_CANCER = "Cancer";
lang.EXPOSURERISK_DIAGNOSED_STROKE = "Stroke";
lang.EXPOSURERISK_DIAGNOSED_MENTALILLNESS = "Mental Illness";
lang.EXPOSURERISK_DIAGNOSED_NEURODEGENERATIVE = "Neurodegenerative Disease";
lang.EXPOSURERISK_DIAGNOSED_NONE = "None";

lang.EXPOSURERISK_CONSUMEDRUGS_TEXT = "Do you consume any drugs ?";
lang.EXPOSURERISK_YES_PRECRIBED = "Yes, Precribed Medications";
lang.EXPOSURERISK_YES_HERBAL = "Yes, Herbal Remedies or Vitamins";
lang.EXPOSURERISK_YES_COUNTER = "Yes, Over the Counter Medications";
lang.EXPOSURERISK_YES_RECREATIONAL = "Yes, Recreational Drugs";
lang.EXPOSURERISK_CONSUMEDRUGS_NONE = "None";

lang.EXPOSURERISK_PETHOME_TEXT = "Do you have pets at home?";
lang.EXPOSURERISK_PETHOME_ONE = "One";
lang.EXPOSURERISK_PETHOME_TWO = "Two or More";
lang.EXPOSURERISK_PETHOME_NONE = "None";

lang.EXPOSURERISK_PEOPLE_TEXT = "How many people do you live with?";
lang.EXPOSURERISK_PEOPLE_ONE = "One";
lang.EXPOSURERISK_PEOPLE_TWO = "Two or More";
lang.EXPOSURERISK_PEOPLE_NONE = "None, I live alone";

lang.EXPOSURERISK_SMOKE_TEXT = "Do you smoke?";
lang.EXPOSURERISK_SMOKE_ONE = "Yes";
lang.EXPOSURERISK_SMOKE_TWO = "Sometimes";
lang.EXPOSURERISK_SMOKE_NONE = "No";

lang.EXPOSURERISK_ALCOHOL_TEXT = "Do you consume alcohol?";
lang.EXPOSURERISK_ALCOHOL_NONE = "No";
lang.EXPOSURERISK_ALCOHOL_ONE = "Yes";
lang.EXPOSURERISK_ALCOHOL_TWO = "Sometimes";
// lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Did you go to work during your country\'s lockdown?';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Yes, but I interacted with few people per day';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Yes, and I interacted with many people per day';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Yes, and I interacted with many people/patients per day in a healthcare setting.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'What did you to avoid catching COVID-19 outside your home?';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'I always kept 6 ft/2 m distance from people.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'I always wore a mask/face covering.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'I never spent more than 30 minutes indoors/in vehicles with others.';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'I frequently washed my hands and rarely touched my face.';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Outside your home did you spend time with anyone who had or soon after developed...?';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Confirmed COVID-19 (Positive diagnosis)';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Possible COVID-19 symptoms';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'None of the above';
// lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'How many people do you live with? (Please type a number, e.g. 2)';
// lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Do you think anyone in your home might have had COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = "Yes, possibly";
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY =
  "Yes, definitely (Positive test result)";
lang.ILLHOUSEMATES_PAGE_TITLE = "Ill Housemates ~ 6 min to complete quiz";
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT =
  "How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2";
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT =
  "When did the first person in your house become ill?";

lang.WEREYOUILL_PAGE_TITLE =
  "Have you felt unwell lately? ~ 6 min to complete the questionnaire";
lang.WEREYOUILL_UNWELL_TEXT = "Have you been bad lately?";
lang.WEREYOUILL_PHYSICAL_MANIFESTATION =
  "Yes, I have had physical manifestations.";
lang.WEREYOUILL_PHYSICAL_SYMPTOMS = "Yes, but without physical symptoms.";
lang.WEREYOUILL_NO = "No";

lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL =
  "I'm beginning to feel ill";
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = "I'm now feeling quite ill";
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = "I'm feeling very ill";
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER =
  "I'm still ill but feeling better";
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE =
  "I was recovering, but now feeling worse again";
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD =
  "Yes, but I feel fully recovered";
lang.YOURSYMPTOMS_PAGE_TITLE = "Your Symptoms ~ 4 min to complete quiz";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT =
  "Which of the following symptoms did/do you have?";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = "Fever";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_HEADACHES = "Headaches";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_BOWELSYMPTOMS = "Bowel symptoms";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VISUALCHANGES = "Visual Changes";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SWOLLEINGPROBLEMS =
  "Swollowing Problems";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASHES = "Skin Rashes";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTLESS_BREATES =
  "Shortness of breath";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHESTPAIN = "Chest Pain";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_WEIGTHGAIN = "Weight Gain";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ABDOMINALPAIN = "Abdominal Pain";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_HEARINGPROBLEMS = "hearing problems";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_URINARYPROBLEMS = "Urinary symptoms";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_JOINTPAIN = "Joint pain";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TIREDNESS = "Tiredness/Fatigue";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSAPPETITE = "Loss of Appetite";
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_WEIGTHLOSS = "Weight loss";

lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT =
  "How hard did you, or do you find it to breathe? (2 = mild shortness of breath, 8 = severe shortness of breath)";
// lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'How bad did you, or do you feel overall? (2 = only slightly ill, 8 = seriously ill/went to the hospital)';
// lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'How anxious were/are you about COVID19? (2 = a little anxious, 8 = very anxious)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT =
  "When did you first experience symptoms?";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT =
  "How long did you feel ill/have symptoms for?";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = "A few days or less";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK =
  "Less than one week";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = "One to two weeks";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS =
  "Two to three weeks";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS =
  "More than three weeks";
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = "I'm still ill!";
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Did/Do you think you were infected with Coronavirus / COVID-19?';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'No way';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'A small chance (~25% sure)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'A reasonable chance (~50% sure)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Pretty sure (~75% sure)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolutely convinced (~90%+ sure)';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Yes, 100%: I tested positive';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Yes, 100%: I was treated in hospital';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Yes, 100%: I was treated in intensive care';
lang.YOURSYMPTOMS_PERSONAL_HISTORY =
  "Do you have any personal or family history which might be relevant?";
lang.YOURSYMPTOMS_SYMPTOMS_SATRTFEELINGSICK =
  "Which symptoms have you had and how strong have they been since you started feeling sick?";

lang.COVIDTESTING_PAGE_TITLE = "Free Response ~ 3 min to complete quiz";
lang.COVIDTESTING_IDEAABOUT_TEXT =
  "Do you have any idea about what could be going on?";
lang.COVIDTESTING_CONCERNING_TEXT =
  "Is there anything which is concerning you at the moment?";
lang.COVIDTESTING_DISCUSSION_TEXT =
  "Was there anything you were hoping for from our discussion today?";

// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Have you done a lateral flow antibody test to check if you have/had COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO =
  "No, I've not done a lateral flow antibody test, or any other COVID tests.";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST =
  "No, but I've done a different COVID test.";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE =
  "Yes, and I tested positive";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE =
  "Yes, but I tested negative";
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW =
  "Yes, but I don't know the result";
// lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'What date did you do the test?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT =
  "What kind of antibody test did you do to see if you have/had COVID-19?";
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY =
  "Rapid Antibody blood test (Lateral flow immunoassay)";
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST =
  "Sent blood for laboratory antibody test";
lang.ANTIBODYTEST_PAGE_TITLE = "Antibody test ~ 2 min to complete quiz";
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'What did your test result look like?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = "C only";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = "C and IgG";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = "C and IgM";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = "C, IgM and IgG";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = "IgG only (no C)";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = "IgM only (no C)";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM =
  "IgG and IgM only (no C)";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = "None, no lines";
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = "None of the above";
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT =
  "Please upload a photo of the antibody test taken from directly above, with even lighting so that the test cassette fills the screen (like the one below). ";
lang.OTHERTESTS_PAGE_TITLE = "Other tests ~ 1 min to complete quiz";
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT =
  "Did you send blood for laboratory testing?";
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = "Have you done any rapid testing?";
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT =
  "Have you done viral and feces test?";
lang.OTHERTESTS_XRAYQUESTION_TEXT = "Have you had an X-ray/CT scans of test?";
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT =
  "Have you consulted with a licensed practitioner?";
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = "No";
lang.OTHERTESTS_YES_PRACTITIONER =
  "Yes,and I can Share results with practitioner";
lang.OTHERTESTS_YES_REPEAT = "Yes,but I would like to repeat it";
lang.OTHERTESTS_YES_WAITITNG = "Yes, I am still waiting on the results";
lang.EMAILCONSENT_PAGE_TITLE = "Final question ~ 30 sec to complete quiz";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT =
  "Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO =
  "Interested, send me more information when you hear about it";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO =
  "Interested, but no information please";
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = "No, not interested";
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT =
  "Please leave your email address below";
lang.RESULT_PAGE_TITLE = "Results";
lang.RESULT_PAGE_TEXT = "You will receive your results by email shortly.";

// AQ model translater
lang.ANSWER_INSTRUCTIONS_TITLE = "INSTRUCTIONS";
lang.ANSWER_INSTRUCTIONS_PARA =
  "To optimize the performance of this app, it is recommended that you undergo a medical examination before use. If you have already taken the test or exam and obtained the results and any potential diagnosis, please ensure that you have them readily available.";
lang.ANSWER_CONFIDENTIALITY_TITLE = "CONFIDENTIALITY NOTICE";
lang.ANSWER_CONFIDENTIALITY_PARA1 =
  "Please be informed that a team of medical professionals has developed this app. We assure you that any personally identifiable information you provide will always be treated with the utmost confidentiality. Under no circumstances will your information be shared, sold, or disclosed to any third party without your explicit permission.";
lang.ANSWER_CONFIDENTIALITY_PARA2 =
  "The primary objective of this app is to assist researchers in gaining insights and improving the understanding of the patient journey for individuals.";
lang.ANSWER_CONFIDENTIALITY_PARA3 =
  "For further details or inquiries, please reach out to us at: privacy@vibesdoc.com";

// clicking logo model translator
lang.LOGO_CLICK_PARA1 =
  "Welcome to Vibesdoc, a revolutionary healthcare platform that focuses on enhancing patient health by prioritizing wellness";
lang.LOGO_CLICK_PARA2 =
  "At Vibesdoc, we understand that good health is not just about treating illnesses but also about taking proactive steps towards a healthy lifestyle. That's why we empower our patients to connect and chat with qualified practitioners of their choice, so they can get checked and tested for immediate relief from any conditions they may be experiencing. Our user-friendly platform allows patients to easily maintain their long-standing health by making lifestyle changes supporting their wellness. Our goal is to provide a comprehensive and holistic approach to healthcare that puts the patient's well-being first.";
lang.LOGO_CLICK_PARA3 =
  "Take the steps towards a healthier and happier life with Vibesdoc.";
lang.LOGO_CLOSE = "Close";

// FAQ translator
lang.FAQ_HEADING = "FAQ";
lang.FAQ_PARA1 =
  "For general information about Coronavirus and COVID-19 please follow";
lang.FAQ_LINK = "CDC guidelines";
lang.FAQ_ABOUT_TITLE = "About the Questionnaire";
lang.FAQ_ABOUT_QUES1 = "Why is the questionnaire so long?";
lang.FAQ_ABOUT_ANS1 =
  "Our software leverages various data sources to give the most reliable prediction; thus, the most complete and exhaustive information is required for an accurate prediction.";
lang.FAQ_ABOUT_QUES2 = "Do I have to answer all questions?";
lang.FAQ_ABOUT_ANS2 =
  "YES! We know its a lot, but complete information will allow the algorithm to predict your potential exposure and risk most reliably.";
lang.FAQ_ABOUT_QUES3 =
  "I did not perform any tests. Can I still use your questionnaire?";
lang.FAQ_ABOUT_ANS3 =
  "Yes, you can. Our questionnaire works much better when combined with a test; however, the algorithm can still predict with some accuracy whether your case might correlate with a particular disease.";
lang.FAQ_ABOUT_QUES4 =
  "Why do I need to take the questionnaire? Won't a test be enough?";
lang.FAQ_ABOUT_ANS4 =
  "Although tests are reasonably accurate, if they are applied to a population with a low rate of disease, then a positive result is not very predictive. The questionnaire assesses your risk of disease, and the test can confirm or infirm (deny) the prediction. Think of it like having a second opinion on a medical diagnosis. If two doctors independently agree, you can be much more confident than if they disagree.";
lang.FAQ_DATAPRIVACY_TITLE = "Data privacy";
lang.FAQ_DATAPRIVACY_QUES1 = "Are my results public?";
lang.FAQ_DATAPRIVACY_ANS1 =
  "No, your results are not public. Your personally identifiable results will not be shared with anyone (including researchers, organisations, or governments) outside of Silibrain LLC. Silibrain LLC maintains this data only for reference with our clinicians under the strictest privacy policies as with our patient's data. You retain every right to have your responses immediately deleted from our patient records and hence automatically from all of our de-identified research datasets at any time by contacting privacy@vibesdoc.com.";
lang.FAQ_DATAPRIVACY_QUES2 =
  "What will happen with my data? How is it stored and distributed?";
lang.FAQ_DATAPRIVACY_ANS2 =
  "Your de-identified/anonymised data (no possible way of connecting the data with you) will be used for research purposes. Your data is stored with the highest security standards according to HIPAA and GDPR.";
lang.FAQ_DATAPRIVACY_QUES3 = "Do you keep my data private?";
lang.FAQ_DATAPRIVACY_ANS3 =
  "Your personally identifiable results will not be shared with anyone (including researchers, organisations, or governments) outside of Silibrain LLC. Silibrain LLC maintains this data only for reference with our clinicians under the strictest privacy policies as with our patient's data. You retain every right to have your responses immediately deleted from our patient records and hence automatically from all of our de-identified research datasets at any time by contacting privacy@vibesdoc.com.";
lang.FAQ_RESULTS_TITLE = "My Results";
lang.FAQ_RESULTS_QUES1 = "Where can I find my results?";
lang.FAQ_RESULTS_ANS1 =
  "Your result is only available after completing the test; we would not store the result linked to any email address or name at this time, so we won't be able to retrieve it specifically.";
lang.FAQ_RESULTS_QUES2 = "Does a medical professional review my result?";
lang.FAQ_RESULTS_ANS2 =
  "No. Several medical professionals have reviewed the process, but a medical professional does not independently review each result.";
lang.FAQ_RESULTS_QUES3 =
  "I've got my questionnaire result. Do I still have to take a medical exam?";
lang.FAQ_RESULTS_ANS3 =
  "Suppose you only took the questionnaire and got a positive or negative result. In that case, you can make the diagnosis much more accurate by taking a medical exam and following up with a medical professional. We do not recommend relying on the app alone.";
lang.FAQ_RESULTS_QUES4 = "What does my result mean?";
lang.FAQ_RESULTS_ANS4 =
  "You should have seen a detailed explainable personal result if you have completed the questionnaire and test. The most likely outcomes are that either the test or the algorithm agrees with the diagnosis. In this case, you can be confident that the results will likely be correct. If the test and algorithm disagree, this may mean that the test result was a false positive or negative. In this scenario, we recommend repeating the test and follow up with a medical professional. ";
lang.FAQ_RESULTS_QUES5 = "My result was negative. Does it mean I was not sick?";
lang.FAQ_RESULTS_ANS5 =
  "If the algorithm and test both gave a negative result, you would unlikely have been sick. It is possible but unlikely that you were ill, did not show typical symptoms or other indicators, and did not develop a strong antibody response. In this unusual case, assuming you have not been sick may be safer anyway. ";
lang.FAQ_RESULTS_QUES6 = "My result was positive. What does it mean?";
lang.FAQ_RESULTS_ANS6 =
  "You would likely have been sick if the algorithm and test strip gave a positive result. There is not enough evidence to suggest that the lack of family history, specific symptoms, lifestyle or immunisation preferences will eliminate the risk of catching a disease. Many studies are being done on the immunity/protection provided by antibodies, and this answer will be updated when new evidence arises.";
lang.FAQ_ALGORITHM_TITLE = "Algorithm";
lang.FAQ_ALGORITHM_QUES1 = "How does the prediction algorithm work?";
lang.FAQ_ALGORITHM_ANS1 =
  "OpenAI's ChatGPT API is used to absorb answers from a patient questionnaire and correlate them with potential diseases by following a structured approach. Here's a general outline of the process:";
lang.FAQ_ALGORITHM_ANS2 =
  "A comprehensive questionnaire that covers relevant symptoms, medical history, lifestyle factors, and any other pertinent information related to potential diseases is prepared.";
lang.FAQ_ALGORITHM_ANS3 =
  "The questionnaire is presented to the patient through a web form or a conversational interface powered by ChatGPT. The patient would answer each question, which could be text inputs or selecting predefined options.";
lang.FAQ_ALGORITHM_ANS4 =
  "Once the patient completes the questionnaire, the responses must be processed and validated. This involves checking for completeness, ensuring responses are within expected ranges or formats, and handling missing or ambiguous answers.";
lang.FAQ_ALGORITHM_ANS5 =
  "To correlate the responses with potential diseases, it is essential to convert the answers into a structured format that the AI model can process. This involves creating a standardised representation of symptoms, encoding medical conditions, and assigning numerical values to certain factors.";
// lang.FAQ_ALGORITHM_ANS6 = "Questions such as, "Based on the symptoms described, what are the potential diseases and their probabilities?" are formulated to utilise the ChatGPT API to query the AI model with the structured data derived from the patient's responses.";
lang.FAQ_ALGORITHM_ANS7 =
  "Relevant information is parsed and extracted to utilise the model best. The model generates a list of potential diseases and associated probabilities for each condition based on the provided symptoms and factors.";
lang.FAQ_ALGORITHM_ANS8 =
  "Results are presented and displayed to the patient clearly and clearly. ";
lang.FAQ_ALGORITHM_ANS9 =
  "Along with the results, additional context can be provided, such as the limitations of the analysis, potential biases, and recommendations for further medical evaluation or seeking professional advice.";
lang.FAQ_ALGORITHM_ANS10 =
  "The questionnaire has to be continuously refined, and data processing and model interactions are based on feedback and evaluation. This iterative process helps improve the accuracy and effectiveness of the system over time.";
lang.FAQ_ALGORITHM_ANS11 =
  "It's important to note that while ChatGPT can provide insights and potential correlations, it should not be considered a substitute for professional medical advice or diagnosis. Always consult qualified healthcare professionals for accurate assessments and recommendations regarding health concerns.";
lang.FAQ_RECOMENDATION_TITLE = "Recomendations";
lang.FAQ_RECOMENDATION_QUES1 = "Can I share my results?";
lang.FAQ_RECOMENDATION_ANS1 =
  "If you would like to share your results, you are free to do so. If you would like to share the website and recommend that friends and family try it, we would greatly appreciate it.";
lang.FAQ_RECOMENDATION_QUES2 = "Can I use my results as proof of immunity?";
lang.FAQ_RECOMENDATION_ANS2 =
  "No, these results are not currently accepted as proof of immunity. We recommend following up with a medical professional.";
lang.FAQ_RECOMENDATION_QUES3 =
  "Can I return to everyday daily life if the results are negative?";
lang.FAQ_RECOMENDATION_ANS3 =
  "You should contact your medical practitioner and follow up on their recommendations on the adequate next steps. It is your decision how to use this information. However, we strongly suggest you consider your risk and those of others around you.";

export default lang;
