import * as React from "react";
import { useContext } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { translationContext } from "../../services/TranslateService";
import Header from "../../components/Header";
import * as arrow from "../../assets/images/arrow.png";
import * as watch from "../../assets/images/watch.png";
import * as question from "../../assets/images/question.png";
import * as banner from "../../assets/images/banner.png";
import useGlobalCommonStyles from "../../useGlobalCommonStyles";
import HomeFooter from "../../components/HomeFooter";
// import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Axios from "axios";
import DoctorModel from "../../components/DoctoeModel";
const useStyles = makeStyles((theme) => ({
  mainContent: {
    // marginTop: "2rem",
    position: "absolute",
    // border: '1px solid blue',
    textAlign: "center",
    top: "25%",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  section: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: theme.palette.background.paper,
    marginTop: "1rem",
    borderRadius: "2%",
    padding: "0.5rem 1rem",
    color: theme.palette.primary.dark,
    "&:hover": {
      filter: "drop-shadow(0 0 0.3rem silver)",
      transition: "filter 0s",
    },
  },
  sectionContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const MainPage: React.FunctionComponent<null> = () => {
  const [t] = useContext(translationContext);
  const [open, setOpen] = React.useState(false);
  // const [doctor, setDoctor] = React.useState([]);

  // function handleRequest() {
  //   Axios.get(
  //     "https://vcc.arl.mybluehostin.me/zander/Install/default/get_users"
  //   )
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setDoctor(response.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();
  const commonStyles = useGlobalCommonStyles();
  React.useEffect(() => {
    if (localStorage.getItem("questionnaire")) {
      localStorage.removeItem("questionnaire");
    }
  }, []);

  return (
    <Grid container alignItems="center">
      <article className={commonStyles.wrapper}>
        <main>
          <div
            style={{
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              backgroundRepeat: "no-repeat",
              width: "100vw",
              height: "100vh",
            }}>
            <Header />
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.mainContent}>
              <Link to="/questionnaire" className={classes.section}>
                <section
                  aria-label="answer questionnaire"
                  className={classes.sectionContent}>
                  <h5>{t.MAINPAGE_PAGE_QUESTIONNAIRE}</h5>
                  <img alt="questionnaire" src={String(question)} />
                </section>
                <img alt="go" src={String(arrow)} />
              </Link>
              <Box
                // to="#"
                className={classes.section}
                onClick={handleClickOpen}>
                <section
                  aria-label="video instructions"
                  className={classes.sectionContent}>
                  <h5>{t.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS}</h5>
                  <img alt="video" src={String(watch)} />
                </section>
                <img alt="go" src={String(arrow)} />
              </Box>
              <DoctorModel open={open} setOpen={setOpen} showSearch={true} />
            </Grid>
            <HomeFooter />
          </div>
          {/* </div> */}
        </main>
      </article>
    </Grid>
  );
};

export default MainPage;
