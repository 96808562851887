/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { translationContext } from "../../../services/TranslateService";
import codiApiClient from "../../../services/codi-api-client.service";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useEffect } from "react";
import fbLogo from "../../../assets/images/facebook.png";
import linkedInLogo from "../../../assets/images/LinkedIn.png";
import twitterLogo from "../../../assets/images/Twitter.png";
import intagramLogo from "../../../assets/images/instagram.svg";
import Line from "../../../assets/images/line.svg";
import storage from "../../../services/storage.service";
import { IQuestionnaireState } from "../QuestionnaireState";
import Axios from "axios";
import { Box, CircularProgress } from "@material-ui/core";
import { constants } from "os";
import DoctorModel from "../../../components/DoctoeModel";

const useStyles = makeStyles(() => ({
  root: {
    margin: "5rem 1rem",
    textAlign: "center",
    // border: "1px solid yellow",
  },
}));

// const stats = codiApiClient.results

enum UserHealthStatus {
  good = "good",
  veryGood = "very good",
  bad = "bad",
  veryBad = "very bad",
}

const QuestionnaireResult = () => {
  const questionnaireFromStorage = storage.getObject(
    "questionnaire"
  ) as IQuestionnaireState;

  const [t] = useContext(translationContext);
  const [modal, setModal] = React.useState(true);
  const [result, setResult] = React.useState("");
  const [userStatus, updateUserState] = React.useState<UserHealthStatus>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const responseData = storage.getObject("questionAnswer") as {
      answers: Array<string>;
      questions: Array<string>;
    };

    console.log("answer all the question", responseData);
    // Concatenate the questions and answers
    const inputString = responseData.questions
      .map((q, i) => `Q: ${q}\nA: ${responseData.answers[i]}`)
      .join("\n");

    const obj = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content:
            "Provide only 4 possible diagnoses with the accuracy of prediction only as a conservative medical assessment by combining all the questions and answers, and specify the type of specialist doctor to recommend labelled as 'specialist'. This overall assessment of a patient's health, is derived from the responses provided to the diverse set of questions in the questionnaire" +
            inputString,
        },
      ],
    };
    setIsLoading(true);

    Axios.post(`https://api.openai.com/v1/chat/completions`, obj, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer sk-proj-XtDgkYFCsdQJ24v5xsNUT3BlbkFJXlGRgAaOZj05Ps2N2Ycz",
      },
    })
      .then((res) => {
        // console.log("response from QA", res);

        // const contentData = res.data.choices[0].message.content.split(".");
        // if (contentData[0].includes("very good")) {
        //   updateUserState(UserHealthStatus.veryGood);
        // } else if (contentData[0].includes("good")) {
        //   updateUserState(UserHealthStatus.good);
        // } else if (contentData[0].includes("very bad")) {
        //   updateUserState(UserHealthStatus.veryBad);
        // } else if (contentData[0].includes("bad")) {
        //   updateUserState(UserHealthStatus.bad);
        // }
        const contentData = res.data.choices[0].message.content.split("\n");
        console.log(res.data);
        // console.log("user status", contentData);
        setResult(contentData);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      setOpen(false);
    };
  }, []);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  // const a: string = "good";

  const findWordWithLetter = (statement: string) => {
    const words = statement.split("");
    for (const word of words) {
      if (word.includes("%")) {
        if (word < "25%") {
          return "green";
        } else if (word < "50%") {
          return "yellow";
        } else if (word < "75%") {
          return "orange";
        } else {
          return "red";
        }
      }
    }
    return ""; // Return null if no match is found
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={modal}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogActions>
          <div
            style={{
              width: "100%",
              backgroundColor: "#FFFFFF",
              fontFamily: "Josefin Sans",
              textAlign: "center",
              padding: "10px 20px",
            }}>
            <div
              style={{
                width: "100%",

                fontSize: "20px",
              }}>
              <h4>{t.RESULT_PAGE_TITLE}</h4>
              <p>Output of your Health Status</p>
              {isLoading ? (
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  <CircularProgress />
                </div>
              ) : (
                // <Box>
                //   {userStatus === UserHealthStatus.veryGood && (
                //     <>
                //       <div style={{ background: "#72F34A", padding: "6px" }}>
                //         <p>{result}</p>
                //       </div>
                //     </>
                //   )}
                //   {userStatus === UserHealthStatus.veryBad && (
                //     <>
                //       <div style={{ background: "#EB4026", padding: "6px" }}>
                //         <p>{result}</p>
                //       </div>
                //     </>
                //   )}
                //   {userStatus === UserHealthStatus.bad && (
                //     <>
                //       <div style={{ background: "#F3AC3D", padding: "6px" }}>
                //         <p>{result}</p>
                //       </div>
                //     </>
                //   )}
                //   {userStatus === UserHealthStatus.good && (
                //     <>
                //       <div style={{ background: "#F4EA4F", padding: "6px" }}>
                //         <p>{result}</p>
                //       </div>
                //     </>
                //   )}
                // </Box>
                result.length && (
                  <>
                    <Box
                      style={{ textAlign: "left", marginBottom: "5px" }}
                      onClick={handleClickOpen}>
                      <button
                        style={{ background: findWordWithLetter(result[1]) }}>
                        <p>{result[1]}</p>
                      </button>
                    </Box>
                    <Box
                      style={{ textAlign: "left", marginBottom: "5px" }}
                      onClick={handleClickOpen}>
                      <button
                        style={{ background: findWordWithLetter(result[2]) }}>
                        <p>{result[2]}</p>
                      </button>
                    </Box>
                    <Box
                      style={{ textAlign: "left", marginBottom: "5px" }}
                      onClick={handleClickOpen}>
                      <button
                        style={{ background: findWordWithLetter(result[3]) }}>
                        <p>{result[3]}</p>
                      </button>
                    </Box>
                    <Box
                      style={{ textAlign: "left", marginBottom: "5px" }}
                      onClick={handleClickOpen}>
                      <button
                        style={{ background: findWordWithLetter(result[4]) }}>
                        <p>{result[4]}</p>
                      </button>
                    </Box>
                    <DoctorModel open={open} setOpen={setOpen} />
                  </>
                )
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuestionnaireResult;
