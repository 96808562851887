/* eslint-disable @typescript-eslint/quotes */
import * as React from "react";
import { useContext } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router";
import { translationContext } from "../../../services/TranslateService";
import QuestionSelect from "../../../components/QuestionSelect";
import QuestionDatePicker from "../../../components/QuestionDatePicker";
import {
  addQuestionResultActionCreator,
  questionnaireContext,
  QuestionnairePagesPaths,
} from "../QuestionnaireState";
import Footer from "../../../components/Footer";
import HCCSFocusError from "../../../components/HCCSFocusError";
import countries from "../../../services/countries-translate.service";
import useCommonStyles from "../../commonStyles";
import useScrollToTheTop from "../../../hooks/useScrollToTheTop";
import BackNextNavigation from "../../../components/BackNextNavigation";
import { any, number } from "prop-types";
import Axios from "axios";
import { CircularProgress } from "@material-ui/core";
import storage from "../../../services/storage.service";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const TravelContinued = () => {
  const [t, , currentLang] = useContext(translationContext);
  const [questionnaireState, dispatch] = useContext(questionnaireContext);
  const history = useHistory();
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  useScrollToTheTop();

  const [countryItems, setCountryItems] = React.useState([]);
  // const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    Axios.get("https://restcountries.com/v3.1/all")
      .then((res) => {
        // setCountryItems(res);

        const parseCountryName = res.data.map(
          (item: { name: { common: string } }) => ({
            label: item?.name?.common ?? "",
            value: item?.name?.common ?? "",
          })
        );
        setCountryItems(parseCountryName);
      })
      .catch((err) => {
        console.log(err);
      });
    // setCountryItems([{ label: "usa", value: "usa" }]);

    // const obj = {
    //   model: "gpt-3.5-turbo",
    //   messages: [
    //     {
    //       role: "system",
    //       content:
    //         "Please provide an array of objects containing all the country in the world. Each object should have a key-value structure with the keys 'label' and 'value' both containing the country name.",
    //     },
    //   ],
    // };
    // setIsLoading(true);
    // Axios.post(`https://api.openai.com/v1/chat/completions`, obj, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization:
    //       "Bearer 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",
    //   },
    // })
    //   .then((res) => {
    //     const content = res?.data?.choices?.[0]?.message?.content;
    //     setCountryItems(JSON.parse(content));
    //     // setIsLoading(false);
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  }, []);

  const handleFormSubmit = (values: any) => {
    // Add the current form values (questions and answers) to the array
    const responseData = storage.getObject("questionAnswer") as {
      answers: Array<string>;
      questions: Array<string>;
    };
    console.log("get", responseData);
    const Travel = {
      questions: [
        ...(responseData.questions ?? []),
        t.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT,
        t.TRAVELCONTINUED_VISITEDENTERDATE_TEXT,
        t.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT,
      ],

      answers: [
        ...(responseData.answers ?? []),
        values.highestRiskCountry,
        values.dateEnteredCountry,
        values.dateLeftCountry,
      ],
    };
    console.log("set", Travel);

    storage.setObject("questionAnswer", Travel);
    // const { nextPage } = locationAndTravelNext(values);

    dispatch(
      addQuestionResultActionCreator({
        key: "travelContinued",
        value: values,
        activePage: QuestionnairePagesPaths.exposeRisk,
      })
    );

    history.push(QuestionnairePagesPaths.exposeRisk);
  };

  return (
    <>
      <h3 className={commonStyles.textCenter} style={{ color: "#956DF7" }}>
        {t.TRAVELCONTINUED_PAGE_TITLE}
      </h3>
      <Formik
        initialValues={questionnaireState.travelContinued.values}
        validationSchema={Yup.object({
          highestRiskCountry: Yup.string().required(),
          dateEnteredCountry: Yup.string().required(),
          dateLeftCountry: Yup.string().required(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleFormSubmit(values);
        }}>
        {(values) => (
          <Form className={classes.root}>
            {/* {isLoading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <CircularProgress />
              </div>
            ) : ( */}
            <>
              <QuestionSelect
                name="highestRiskCountry"
                questionText={t.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT}
                items={countryItems}
              />
            </>
            {/* )} */}
            <QuestionDatePicker
              name="dateEnteredCountry"
              questionText={t.TRAVELCONTINUED_VISITEDENTERDATE_TEXT}
              locale={currentLang}
            />

            {!!values?.values?.dateEnteredCountry && (
              <QuestionDatePicker
                name="dateLeftCountry"
                locale={currentLang}
                minDate={values?.values?.dateEnteredCountry}
                questionText={t.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT}
              />
            )}
            <HCCSFocusError />
            <BackNextNavigation nextButtonText={t.GLOBAL_LABEL_NEXT} />
          </Form>
        )}
      </Formik>
      <Hidden smDown>
        <Footer />
      </Hidden>
    </>
  );
};

export default TravelContinued;
