import * as React from 'react';
import { useContext } from 'react';
// import Button from "@mui/material/core";
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle } from "@material-ui/core";
import { translationContext } from '../services/TranslateService';
import * as logo from '../assets/images/logo.png';
import * as popup from "../assets/images/popup.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
const useStyles = makeStyles(() => ({
  header: {
    width: "100%",
    padding: "1%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: "70%",
  },
  popup: {
    width: "100%",
  },
}));

export default function AlertDialog() {
  const [t] = useContext(translationContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <div>
      <img
        alt="logo"
        src={String(logo)}
        className={classes.logo}
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t.LOGO_CLICK_PARA1}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
          {t.LOGO_CLICK_PARA2}
          </DialogContentText>
          <img
            alt="popup"
            src={String(popup)}
            className={classes.popup}
          />
          <DialogContentText id="alert-dialog-description">
          {t.LOGO_CLICK_PARA3}
          </DialogContentText>
          {/* <DialogContentText id="alert-dialog-description">
            To know more, Please go to our official website by using this{" "}
            <Link
              to={"/homecovidtests"}
              style={{ textDecoration: "underline" }}>
              link
            </Link>
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t.LOGO_CLOSE}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
