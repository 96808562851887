import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as logo from "../assets/images/logo.png";
import ChangeLanguage from "./ChangeLanguage";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import AlertDialog from "./Models";

const useStyles = makeStyles(() => ({
  header: {
    width: "100%",
    padding: "1%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    top: "0",
    backgroundColor: "rgba(211, 211, 211, 0.5)",
  },
  logo: {
    width: "70%",
  },
}));

const Header: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    <>
      <header className={classes.header}>
        {/* <Link to="/homecovidtests"> */}
        {/* <img
            alt="logo"
            src={String(logo)}
            className={classes.logo}
            // onClick={handleOpen}
            onClick={() => {
              return <AlertDialog />;
            }} */}
        {/* /> */}
        <AlertDialog />
        {/* </Link> */}
        <div>
          {/* <Button onClick={handleOpen}>Open</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Instruction
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                This questionnaire works best if you have completed a medical
                exam
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                Ifyou have one available please ensure you have taken the test,
                remembered what the result looked like, and taken a photograph
                of the test at the ‘reading’ time specified by the
                manufacturer's instructions.
              </Typography>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                CONFIDENTIALITY NOTICE
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                This questionnaire has been created by medical professionals.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                Any personal identifiable information you volunteer will NEVER
                be shared, sold or passed onto anyone else without your exolicit
                permission.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                This has been launched with the sole purpose to help researchers
                learn about deseases, recovery and immunity on all our
                self-reported case stories in real-time
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                For more information contact: privacy@wvibesdoc.com
              </Typography>
            </Box>
          </Modal> */}
          <ChangeLanguage />
        </div>
      </header>
    </>
  );
};

export default Header;
