import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

// lang.GLOBAL_LABEL_YES = 'GLOBAL_LABEL_YES';
// lang.GLOBAL_LABEL_NO = 'GLOBAL_LABEL_NO';
// lang.GLOBAL_LABEL_DO_NOT_KNOW = 'GLOBAL_LABEL_DO_NOT_KNOW';
// lang.GLOBAL_LABEL_NEXT = 'GLOBAL_LABEL_NEXT';
// lang.GLOBAL_LABEL_BACK = 'GLOBAL_LABEL_BACK';
// lang.GLOBAL_LABEL_NONE = 'GLOBAL_LABEL_NONE';
// lang.GLOBAL_LABEL_SUBMIT = 'GLOBAL_LABEL_SUBMIT';
// lang.GLOBAL_FORMVALIDATION_REQUIRED = 'GLOBAL_FORMVALIDATION_REQUIRED';
// lang.GLOBAL_FORMVALIDATION_EMAIL = 'GLOBAL_FORMVALIDATION_EMAIL';
// lang.GLOBAL_ERROR_FORMSUBMISSION = 'GLOBAL_ERROR_FORMSUBMISSION';
// lang.MAINPAGE_PAGE_TITLE = 'MAINPAGE_PAGE_TITLE';
// lang.MAINPAGE_PAGE_TEXT = 'MAINPAGE_PAGE_TEXT';
// lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'MAINPAGE_PAGE_QUESTIONNAIRE';
// lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'MAINPAGE_PAGE_VIDEO_INSTRUCTIONS';
// lang.MAINPAGE_PAGE_TEST_IMAGE = 'MAINPAGE_PAGE_TEST_IMAGE';
// lang.GENERALINFORMATION_PAGE_TITLE = 'GENERALINFORMATION_PAGE_TITLE';
// lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'GENERALINFORMATION_GENDERQUESTION_TEXT';
// lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'GENERALINFORMATION_GENDERQUESTION_MALE';
// lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'GENERALINFORMATION_GENDERQUESTION_FEMALE';
// lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'GENERALINFORMATION_GENDERQUESTION_OTHER';
// lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'GENERALINFORMATION_AGEQUESTION_TEXT';
// lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY';
// lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'GENERALINFORMATION_HEALTHQUESTION_TEXT';
// lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL';
// lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER';
// lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY';
// lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY';
// lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'GENERALINFORMATION_COMMONQUESTION_TEXT';
// lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'GENERALINFORMATION_COMMONQUESTION_NONE';
// lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION';
// lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION';
// lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM';
// lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS';
// lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES';
// lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES';
// lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS';
// lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO';
// lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'GENERALINFORMATION_COMMONQUESTION_KIDNEY';
// lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'GENERALINFORMATION_COMMONQUESTION_LIVER';
// lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT';
// lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'GENERALINFORMATION_COMMONQUESTION_PREGNANT';
// // lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'GENERALINFORMATION_FLUQUESTION_TEXT';
// lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'LOCATIONANDTRAVEL_PAGE_TITLE';
// lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT';
// lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT = 'LOCATIONANDTRAVEL_REGIONQUESTION_TEXT';
// lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT';
// lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT';
// lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN';
// lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN';
// lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN';
// lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS';
// lang.TAKEPHOTO_PAGE_HEADER = 'TAKEPHOTO_PAGE_HEADER';
// lang.TAKEPHOTO_PAGE_BTN = 'TAKEPHOTO_PAGE_BTN';
// lang.TRAVELCONTINUED_PAGE_TITLE = 'TRAVELCONTINUED_PAGE_TITLE';
// lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'TRAVELCONTINUED_VISITEDCOUNTRY_TEXT';
// lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'TRAVELCONTINUED_VISITEDENTERDATE_TEXT';
// lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT';
// lang.EXPOSURERISK_PAGE_TITLE = 'EXPOSURERISK_PAGE_TITLE';
// lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'EXPOSURERISK_GOTOWORKQUESTION_TEXT';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE';
// lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE';
// lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'EXPOSURERISK_SPENDTIMEQUESTION_TEXT';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID';
// lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'EXPOSURERISK_SPENDTIMEQUESTION_NONE';
// lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT';
// lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT';
// lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE';
// lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY';
// lang.ILLHOUSEMATES_PAGE_TITLE = 'ILLHOUSEMATES_PAGE_TITLE';
// lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'ILLHOUSEMATES_NUMBERILLQUESTION_TEXT';
// lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT';
// lang.WEREYOUILL_PAGE_TITLE = 'WEREYOUILL_PAGE_TITLE';
// lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'WEREYOUILL_WEREYOUILLQUESTION_TEXT';
// lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE';
// lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL';
// lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'WEREYOUILL_WEREYOUILLQUESTION_QUITEILL';
// lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'WEREYOUILL_WEREYOUILLQUESTION_VERYILL';
// lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER';
// lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE';
// lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD';
// lang.YOURSYMPTOMS_PAGE_TITLE = 'YOURSYMPTOMS_PAGE_TITLE';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING';
// lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH';
// lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT';
// lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT';
// lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT';
// lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS';
// lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL';
// lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE';
// lang.COVIDTESTING_PAGE_TITLE = 'COVIDTESTING_PAGE_TITLE';
// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT';
// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'COVIDTESTING_DONEANTIBODYTESTQUESTION_NO';
// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST';
// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE';
// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE';
// lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW';
// lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT';
// lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT';
// lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY';
// lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST';
// lang.ANTIBODYTEST_PAGE_TITLE = 'ANTIBODYTEST_PAGE_TITLE';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES';
// lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE';
// lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT';
// lang.OTHERTESTS_PAGE_TITLE = 'OTHERTESTS_PAGE_TITLE';
// lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT';
// lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'OTHERTESTS_SWABTESTQUESTION_TEXT';
// lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'OTHERTESTS_VIRALANTIGENQUESTION_TEXT';
// lang.OTHERTESTS_XRAYQUESTION_TEXT = 'OTHERTESTS_XRAYQUESTION_TEXT';
// lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT';
// lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'OTHERTESTS_DIDNOTDOOPTION_TEXT';
// lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'OTHERTESTS_NEGATIVEOPTION_TEXT';
// lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'OTHERTESTS_POSITIVEOPTION_TEXT';
// lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'OTHERTESTS_UNKNOWNOPTION_TEXT';
// lang.EMAILCONSENT_PAGE_TITLE = 'EMAILCONSENT_PAGE_TITLE';
// lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'EMAILCONSENT_EMAILCONSENTQUESTION_TEXT';
// lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO';
// lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO';
// lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED';
// lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'EMAILCONSENT_EMAILADDRESSQUESTION_TEXT';
// lang.RESULT_PAGE_TITLE = 'RESULT_PAGE_TITLE';
// lang.RESULT_PAGE_TEXT = 'RESULT_PAGE_TEXT';

export default lang;
